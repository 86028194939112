import React from 'react'
import styled from 'styled-components'
import {
  Flex,
  Card,
  CardBody,
} from '@pancakeswap/uikit'
import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingCard = styled(Card)`
  background: var(--blackColor);
  border: none;
  box-sizing: border-box;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const LoadingBody = styled(CardBody)`
  width: 100%;
  height: 100%;
`

const LoadingImage = styled.img`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <LoadingCard>
        <LoadingBody>
          <Flex alignItems="center" justifyContent="center">
            <LoadingImage src='/images/loading-arktory.gif' alt='asset' width="280px" />
          </Flex>
        </LoadingBody>
      </LoadingCard>
    </Wrapper>
  )
}

export default PageLoader
