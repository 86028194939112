import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useDispatch } from 'react-redux'
import { fetchChainIdFromUrl, fetchUserNetwork } from 'state/network'
import { CHAIN_PARAMS } from 'config/constants/networks'

const useSwitchNetwork = () => {
  const { chainId, account, library } = useWeb3React()
  const dispatch = useDispatch()
  const switchNetwork = useCallback(
    (userChainId: number) => {
      if (account && userChainId !== chainId) {
        try {
          library?.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: CHAIN_PARAMS[userChainId].chainId }],
          })
          dispatch(fetchChainIdFromUrl(false))
        } catch (error: any) {
          if (error.code === 4902) {
            library.provider.request({
              method: 'wallet_addEthereumChain',
              params: [CHAIN_PARAMS[userChainId]],
            })

            try {
              library?.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: CHAIN_PARAMS[userChainId].chainId }],
              })
            } catch (e) {
              console.debug('Added network but could not switch chains', e)
            }
          } else {
            console.warn(error)
          }
        }
      } else {
        dispatch(fetchUserNetwork(chainId, account, userChainId))
      }
    },
    [chainId, account, library, dispatch],
  )
  return { switchNetwork }
}

export default useSwitchNetwork
