// Network chain ids
export enum CHAIN_ID {
  MAINNET = 1,
  ROPSTEN = 3,
  GOERLI = 5,
}

// Network labels
export const NETWORK_LABEL = {
  [CHAIN_ID.MAINNET]: 'Ethereum',
  [CHAIN_ID.ROPSTEN]: 'Ropsten',
  [CHAIN_ID.GOERLI]: 'Goerli',
}

// Network RPC nodes
export const NETWORK_RPC = {
  [CHAIN_ID.MAINNET]: [`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
  [CHAIN_ID.ROPSTEN]: [`https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
  [CHAIN_ID.GOERLI]: [`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
}

// Network block explorers
export const BLOCK_EXPLORER = {
  [CHAIN_ID.MAINNET]: 'https://etherscan.io/',
  [CHAIN_ID.ROPSTEN]: 'https://ropsten.etherscan.io/',
  [CHAIN_ID.GOERLI]: 'https://goerli.etherscan.io/',
}

// For switch network configuration
export const CHAIN_PARAMS = {
  [CHAIN_ID.MAINNET]: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'eth',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.MAINNET],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.MAINNET]],
    blockExplorerName: 'Etherscan'
  },
  [CHAIN_ID.ROPSTEN]: {
    chainId: '0x3',
    chainName: 'Ropsten Test Network',
    nativeCurrency: {
      name: 'eth',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.ROPSTEN],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.ROPSTEN]],
    blockExplorerName: 'Etherscan'
  },
  [CHAIN_ID.GOERLI]: {
    chainId: '0x5',
    chainName: 'Goerli Test Network',
    nativeCurrency: {
      name: 'eth',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.GOERLI],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.GOERLI]],
    blockExplorerName: 'Etherscan'
  }
}

interface BaseChainInfo {
  readonly label: string
  readonly logoUrl: string
  readonly explorer: string
}

export type ChainInfoMap = { readonly [chainId: number]: BaseChainInfo }

export const CHAIN_INFO: ChainInfoMap = {
  [CHAIN_ID.MAINNET]: {
    label: NETWORK_LABEL[CHAIN_ID.MAINNET],
    logoUrl: '/images/ethereum-logo.png',
    explorer: BLOCK_EXPLORER[CHAIN_ID.MAINNET],
  },
  [CHAIN_ID.ROPSTEN]: {
    label: NETWORK_LABEL[CHAIN_ID.ROPSTEN],
    logoUrl: '/images/ethereum-logo.png',
    explorer: BLOCK_EXPLORER[CHAIN_ID.ROPSTEN],
  },
  [CHAIN_ID.GOERLI]: {
    label: NETWORK_LABEL[CHAIN_ID.GOERLI],
    logoUrl: '/images/ethereum-logo.png',
    explorer: BLOCK_EXPLORER[CHAIN_ID.GOERLI],
  }
}
