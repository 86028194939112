import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Flex, useModal } from '@pancakeswap/uikit';
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth';
import { ButtonStyled, GeneralText } from 'components/GlobalStyle'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import { useCookies } from 'react-cookie';
import LoginModal from 'components/DAppLoginModal';
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { parseJwt } from 'utils'
import useToast from 'hooks/useToast'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useAuthWithEmail from 'hooks/useAuthWithEmail'
import useActiveLoginIsAuth from 'hooks/useActiveLoginIsAuth'
import useActiveLoginReact from 'hooks/useActiveLoginReact'
import { axiosGet, axiosPost } from "utils/callAPI"
import useMiddlewareTranslation from 'hooks/useMiddlewareTranslation';
import FixedPageCenterLoader from 'components/Loader/FixedPageCenterLoader';
import UserMenuSelector from './UserMenuSelector'
import LangSelector from '../LangSelector'
import WalletModule from './WalletModule'
import SubMenu from '../DAppSubMenu'

const StyledLogoPCView = styled.img`
  margin-left:24px;
  @media only screen and (max-width: 1400px) {
    display: none;
  }
`

const StyledLogoMobileView = styled.img`
  @media only screen and (min-width: 1400px) {
    display: none;
  }
  @media only screen and (max-width: 530px) {
    display: none;
  }
`

const CurrentLifeCycle = `${process.env.REACT_APP_RELEASE_LIFECYCLE}`.toLowerCase()

const DAppMenu = () => {
  const intl = useIntl()

  const formSchema = Yup.object().shape({
    username: Yup.string()
      .required(intl.formatMessage({ id: "username-is-required", defaultMessage: "Username is required" }))
      .max(100, intl.formatMessage({ id: "username-too-long", defaultMessage: "username too long" })),
    email: Yup.string()
      .required(intl.formatMessage({ id: "email-is-required", defaultMessage: "Email is required" }))
      .matches(/^\S+@\S+$/i, intl.formatMessage({ id: "invalid-email-address", defaultMessage: "Invalid email address" })),
    password: Yup.string()
      .required(intl.formatMessage({ id: "password-is-required", defaultMessage: "Password is required" }))
      .min(8, intl.formatMessage({ id: "password-length-should-be-at-least-8-characters", defaultMessage: "Password length should be at least 8 characters" })),
    confirmpassword: Yup.string()
      .required(intl.formatMessage({ id: "confirm-password-is-required", defaultMessage: "Confirm password is required" }))
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: "password-match-and-should-match", defaultMessage: "Passwords must and should match" })),
  })
  const validationOpt = { resolver: yupResolver(formSchema) }
  const { account, library } = useWeb3React();
  const { email } = useActiveLoginIsAuth();
  const { token, walletAddress } = useActiveLoginReact();
  const { logoutAll } = useAuthWithEmail();
  const [cookie, setCookie, removeCookie] = useCookies();
  const { Group, Control } = { ...Form }
  const [showMenu, setshowMenu] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm(validationOpt)
  const { toastSuccess, toastError } = useToast();
  const { t, setLanguage, currentLanguage } = useTranslation()
  const [onLoginModal] = useModal(<LoginModal onDismiss={() => { /* do nothing */ }} />)
  const [isShowSignUpWithWeb3, setisShowSignUpWithWeb3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currLocation = window.location.pathname.split("/")[1]
  const { logout } = useAuth();
  const { onGetTranslation } = useMiddlewareTranslation()
  // trigger login with web3 wallet
  useEffect(() => {
    async function checkLoginWithWeb3Wallet() {
      try {
        if (account) {
          if ((!token) || (token && (walletAddress !== account) && walletAddress !== null)) {
            const checkUserExistsStatus = await axiosGet(`${process.env.REACT_APP_DOMAIN_LINK}auth/user?wallet_address=${account}`);
            if (checkUserExistsStatus.error === true || (checkUserExistsStatus.message !== "OK")) {
              if (checkUserExistsStatus.message === "user not found") {
                toastSuccess("Wallet Connected", `${account.substring(0, 10)}...${account.substring(account.length - 10)}`);
                setisShowSignUpWithWeb3(true);
              }
            } else {
              setIsLoading(true);
              setisShowSignUpWithWeb3(false);
              const paramsChallenge = {
                address: account
              }
              const getUserChallenge = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/challenge`, paramsChallenge, { validateStatus: () => true });
              if (getUserChallenge.error === true || (getUserChallenge.message.toUpperCase() !== "OK")) {
                throw new Error(getUserChallenge.message);
              }
              if (getUserChallenge.message.toUpperCase() === "OK") {
                const signer = await library.getSigner();
                let signature = "";
                await signer.signMessage(JSON.stringify(`Arktory login : ${getUserChallenge.data.challenge}`)).then((result) => {
                  signature = result
                });
                const paramsLoginWithChallenge = {
                  'address': account,
                  'challenge': getUserChallenge.data.challenge,
                  'response': signature
                }
                const loginWithChallengeResponse = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/login`, paramsLoginWithChallenge, { validateStatus: () => true });
                if (loginWithChallengeResponse.error === true) {
                  throw new Error(loginWithChallengeResponse.message);
                } else if (loginWithChallengeResponse.error === false) {
                  if (loginWithChallengeResponse.message !== 'OK') {
                    throw new Error(loginWithChallengeResponse.message);
                  } else {
                    const decodeJwt = parseJwt(loginWithChallengeResponse.data.token);
                    const expiresJwt = new Date(decodeJwt.exp * 1000);
                    setCookie(
                      '_account',
                      JSON.stringify({
                        token: loginWithChallengeResponse.data.token, iat: decodeJwt.iat,
                        exp: decodeJwt.exp,
                        // exp: (Date.now() + 20 * 1000) / 1000,
                        walletAddress: decodeJwt.walletAddress,
                        uuid: decodeJwt.uuid,
                        loginType:
                          decodeJwt.loginType,
                        hash: decodeJwt.hash
                      }),
                      {
                        path: '/',
                        expires: expiresJwt,
                        // expires: new Date(Date.now() + 20 * 1000),
                        // maxAge: 1000,
                        secure: true,
                        httpOnly: true,
                      }
                    )
                    // toastSuccess("Successfully login!");
                    setIsLoading(false);
                  }
                }

              }

            }
            if (walletAddress && walletAddress !== account) {
              toastError("You're connecting a different wallet.", `${account.substring(0, 10)}...${account.substring(account.length - 10)}`);
            }
          }
        }
      } catch (e: any) {
        if (localStorage) {
          localStorage.removeItem(`connectorIdv2`);
        }
        logout();
        setIsLoading(false);
        toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
        console.error(e);
      }

    }
    if (!isShowSignUpWithWeb3 && (CurrentLifeCycle !== "alpha")) {
      checkLoginWithWeb3Wallet();
    }
  }, [onGetTranslation, account, token, isShowSignUpWithWeb3, intl, walletAddress, library, setCookie, logout, t, toastError, toastSuccess])

  // trigger bind with web3 wallet
  useEffect(() => {
    async function checkBindWithWeb3Wallet() {
      try {
        if (account) {
          if (token && walletAddress === null) {
            setIsLoading(true);
            const config = {
              headers: {
                authorization: token
              },
              validateStatus: () => true
            }
            const getAccountDetails = await axiosGet(`${process.env.REACT_APP_DOMAIN_LINK}auth/isAuthenticated`, config);
            if (getAccountDetails.error === true || getAccountDetails.message !== "OK") {
              throw new Error(getAccountDetails.message);
            }
            else {
              const message = {
                "action": "bindWallet",
                "address": account,
                "email": getAccountDetails.data.email
              };
              const signer = await library.getSigner()
              let signature = "";
              await signer.signMessage(JSON.stringify(message)).then((result) => {
                signature = result
              })
              const params = {
                "address": account,
                "signature": signature
              }
              const bindWalletResponse = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/bindWallet`, params, config);
              if (bindWalletResponse.error === true || bindWalletResponse.message !== "OK") {
                throw new Error(bindWalletResponse.message);
              } else {
                if (localStorage) {
                  localStorage.removeItem(`connectorIdv2`);
                }
                logout();
                removeCookie(
                  '_account'
                );
                setIsLoading(false);
                // setCookie("_account", { token: cookie._account.token, iat: cookie._account.iat, exp: cookie._account.exp, walletAddress: account, uuid: cookie._account.uuid, loginType: cookie._account.loginType, hash: cookie._account.hash });
                toastSuccess(intl.formatMessage({ id: "bind-wallet-successfully", defaultMessage: "Bind wallet successfully" }), intl.formatMessage({ id: "please-sign-in-again-to-refresh-your-login", defaultMessage: "Please sign in again to refresh your login credentials." }));
              }
            }
          }
        }
      } catch (e: any) {
        if (localStorage) {
          localStorage.removeItem(`connectorIdv2`);
        }
        logout();
        setIsLoading(false);
        console.error(e);
        toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
      }

    }
    checkBindWithWeb3Wallet();
  }, [onGetTranslation, account, token, walletAddress, intl, library, setCookie, removeCookie, logout, t, toastError, toastSuccess, cookie])

  const onSubmit = async (data) => {
    try {
      const message = {
        "action": "register",
        "walletAddress": account,
        "username": data.username,
        "email": data.email,
        "password": data.password
      };
      const signer = await library.getSigner()
      let signature = "";

      await signer.signMessage(JSON.stringify(message)).then((result) => {
        signature = result
      })
      const params = {
        "email": data.email,
        "password": data.password,
        "username": data.username,
        "passwordVerify": data.confirmpassword,
        "walletAddress": account,
        "signature": signature

      }
      const registerResponse = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/register`, params, { validateStatus: () => true });
      if (registerResponse.error === true || registerResponse.message !== "OK") {
        throw new Error(registerResponse.message);
      } else {
        toastSuccess(intl.formatMessage({ id: "successfully-register", defaultMessage: "Successfully Register" }));
        setisShowSignUpWithWeb3(false);
      }
    } catch (e: any) {
      console.error(e);
      // if (e.message === undefined) {
      //   toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), e);
      // } else {
      toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
      // }
    }
  }
  return (
    <>
      <div id='navbar' className='navbar-area'>
        <div className='raimo-responsive-nav' style={{}}>
          <div className='container'>
            <div className='raimo-responsive-menu' style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <div role='presentation'>
                {showMenu ? (
                  <StyledI className='bx bx-x' />
                ) : (
                  <StyledI className='bx bx-menu' />
                )}
              </div> */}
              <div className='logo'>
                <a href='/home'>
                  <StyledLogoMobileView src='/logo.png' alt='logo' style={{ maxWidth: '200px' }} />
                </a>
              </div>
              <div className='' style={{}}>
                <div className='d-flex align-items-center' style={{
                  // justifyContent: "right"
                }}>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  {/* <div className='option-item'>
                    <WalletModule />
                  </div> */}
                  <div className='option-item' style={{ marginLeft: "0px" }}>
                    {
                      // !account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />
                      !token ?
                        <ButtonStyled
                          bgColor="transparent"
                          width="100%"
                          height="45px"
                          style={{ border: "hidden" }}
                        >
                          <GeneralText color="var(--neonBLightColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="text-uppercase" onClick={onLoginModal} >
                            {intl.formatMessage({ id: "sign-in", defaultMessage: "Sign in" })}
                          </GeneralText>
                        </ButtonStyled> :
                        // <div style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "50%" }}>
                        //   <i className="fa fa-user" aria-hidden="true" style={{ color: "var(--neonBLightColor)", margin: "5px" }} />
                        // </div>
                        <UserMenuSelector />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className={showMenu ? 'show navbar navbar-expand-md navbar-light' : 'navbar navbar-expand-md navbar-light hide-menu'}>
          <div className='container'>
            <a href='/home' className='navbar-brand'>
              <StyledLogoPCView src='/logo.png' alt='logo' style={{ maxWidth: '200px' }} />
            </a>
            <div className='collapse navbar-collapse mean-menu'>
              <ul className='navbar-nav'>
                {/* <li className='nav-item'>
                    <Link to="/home"> {t("Home")} </Link>
                  </li> */}
                {/* <li className='nav-item'>
                    <Link to="/map" className={currLocation === "map" ? "active" : ""}> {t("Map")} </Link>
                  </li> */}
              </ul>
              <div className='others-option'>
                <div className='d-flex align-items-center'>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  <div className='option-item'>
                    <WalletModule />
                  </div>
                  <div className='option-item' style={{ marginLeft: "0px", marginRight: "25px" }}>
                    {/* {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />} */}
                    {
                      // !account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />
                      !token ?
                        <ButtonStyled
                          bgColor="transparent"
                          width="100%"
                          height="45px"
                          style={{ border: "hidden" }}
                        >
                          <GeneralText color="var(--neonBLightColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="text-uppercase" onClick={onLoginModal} style={{ margin: "5px" }}>
                            {intl.formatMessage({ id: "sign-in", defaultMessage: "Sign in" })}
                          </GeneralText>
                        </ButtonStyled>
                        :
                        // <div style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "50%", margin: "24px" }}>
                        //   <i className="fa fa-user" aria-hidden="true" style={{ color: "var(--neonBLightColor)", margin: "5px" }} />
                        // </div>
                        <UserMenuSelector />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {
          ["map-group", "map-builder"].includes(currLocation) ?
            ''
            :
            <Flex flexDirection="column" style={{ borderTopStyle: "solid", borderBottomStyle: "solid", borderWidth: "1px", borderColor: "#01F3F6" }}  >
              <SubMenu />
            </Flex>
        }
        {isShowSignUpWithWeb3 && (
          <>
            <div style={{ position: "fixed", zIndex: 998, width: "100%", height: "100%", alignItems: "center", textAlign: "center", top: "0px", left: "0px", backgroundColor: "black", opacity: '0.7' }} />
            <div style={{ position: "fixed", zIndex: 999, left: "50%", top: "50%", transform: "translate(-50%,-50%)", maxWidth: "380px", background: "linear-gradient(to left,#4BF5CE,#10A7F2)", borderRadius: "10px", width: "100%", padding: "3px" }}>
              <Flex flexDirection="column" alignItems="center" style={{ padding: "20px 30px", background: "black", borderRadius: "8px" }} >
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                  <GeneralText color="white" fontSize="1.5em" fontFamily="var(--fontFamily2)" weight={700}>
                    {intl.formatMessage({ id: "sign-up", defaultMessage: "Sign Up" })}
                  </GeneralText>
                  <GeneralText color="white" fontSize="0.875em" fontFamily="var(--fontFamily4)" weight={700}>
                    {intl.formatMessage({ id: "please-complete-the-registration-form", defaultMessage: "Please complete the registration form." })}
                  </GeneralText>
                  <Group>
                    <GeneralText color="white" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700}>
                      {intl.formatMessage({ id: "username", defaultMessage: "Username" })}
                    </GeneralText>
                    <Control type="text" placeholder={`${intl.formatMessage({ id: "enter-your-username", defaultMessage: "Enter Your Username" })}`} {...register("username", {
                      // required: {
                      //   value: true,
                      //   message: t('Fill in your username to continue')
                      // },
                      // maxLength: {
                      //   value: 100,
                      //   message: t('Name too long to be real')
                      // }
                    })} />
                  </Group>
                  <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
                    {errors.username?.message}
                  </GeneralText>

                  <Group style={{ marginTop: '15px' }}>
                    <GeneralText color="white" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700}>
                      {intl.formatMessage({ id: "email", defaultMessage: "Email" })}
                    </GeneralText>
                    <Control type="text" placeholder={`${intl.formatMessage({ id: "enter-your-email", defaultMessage: "Enter Your Email" })}`} {...register("email", {
                      // required: {
                      //   value: true,
                      //   message: t('Fill in your email to continue')
                      // },
                      // pattern: {
                      //   value: /^\S+@\S+$/i,
                      //   message: t('Email not valid')
                      // }
                    })} />
                  </Group>
                  <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
                    {errors.email?.message}
                  </GeneralText>

                  <Group style={{ marginTop: '15px' }}>
                    <GeneralText color="white" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700}>
                      {intl.formatMessage({ id: "password", defaultMessage: "Password" })}
                    </GeneralText>
                    <Control type="password" name="password" placeholder={`${intl.formatMessage({ id: "enter-your-password", defaultMessage: "Enter Your Password" })}`} {...register("password", {
                      // required: {
                      //   value: true,
                      //   message: t('Fill in password to continue')
                      // },
                      // pattern: {
                      //   value: /^?=.*?[A-Z]/i,
                      //   message: t('Password format not valid')
                      // }

                    })} />
                  </Group>
                  <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
                    {errors.password?.message}
                  </GeneralText>

                  <Group style={{ marginTop: '15px' }}>
                    <GeneralText color="white" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700}>
                      {intl.formatMessage({ id: "confirm-password", defaultMessage: "Confirm Password" })}
                    </GeneralText>
                    <Control type="password" name="confirmpassword" placeholder={`${intl.formatMessage({ id: "confirm-your-password", defaultMessage: "Confirm Your Password" })}`}{...register("confirmpassword", {
                      // required: {
                      //   value: true,
                      //   message: t('Fill in password to continue')
                      // },
                      // pattern: {
                      //   value: /^@\S+$/i,
                      //   message: t('Password format not valid')
                      // }
                    })} />
                  </Group>
                  <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
                    {errors.confirmpassword?.message}
                  </GeneralText>

                  {/* <Group style={{ marginTop: '15px' }}>
                    <GeneralText color="var(--blackColor)" fontSize="1.1em" fontFamily="var(--fontFamily2)" weight={700}>
                      {t('Wallet Addres')}
                    </GeneralText>
                    <GeneralText color="white" fontSize="1.1em" fontFamily="var(--fontFamily)" weight={700}>
                      {shortenAddress(account, 8)}
                    </GeneralText>
                  </Group> */}

                  <Flex justifyContent="center">
                    <ButtonStyled
                      bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)"
                      width="100%"
                      height="45px"
                      style={{ marginTop: '20px' }}
                    // disabled={submitTx}
                    // endIcon={submitTx ? <AutoRenewIcon spin color="var(--blackColor)" /> : null}
                    >
                      <GeneralText color="var(--blackColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="text-uppercase" >
                        {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
                      </GeneralText>
                    </ButtonStyled>
                  </Flex>
                </form>
              </Flex>
            </div>
          </>
        )}
        {isLoading && (
          <>
            <FixedPageCenterLoader />
            {/* <div style={{ position: "fixed", zIndex: 998, width: "100%", height: "100%", alignItems: "center", textAlign: "center", top: "0px", left: "0px", backgroundColor: "black", opacity: '0.7' }} />
            <div style={{ position: "fixed", zIndex: 999, left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
              <Flex flexDirection="column" alignItems="center">
                <Loading style={{ width: '100px', height: '100px', borderTop: '8px solid transparent', borderBottom: '8px solid transparent' }} />
                <Flex flexDirection="row" style={{ opacity: 1 }}>
                  <GeneralText color="white" fontSize="2rem" fontFamily="var(--fontFamily2)" weight={700}>
                    {intl.formatMessage({ id: "processing", defaultMessage: "Processing" })}
                  </GeneralText>
                  <DotLoading style={{ fontSize: "2rem" }} />
                </Flex>
              </Flex>
            </div> */}
          </>
        )}
      </div>
    </>
  )
}

export default DAppMenu
