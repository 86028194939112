import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'

const MConnectWalletButton = styled(Button)`
  background: linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%);
  border-radius: 0px;
  font-family: var(--fontFamily4);
  font-weight: 700;
  color: var(--blackColor);
  border: 2px solid #01F3F6;
  box-shadow: none;
  border-radius: 5px;
  height: 45px;
`

const ConnectWalletButton = (props) => {
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, undefined, {})
  const intl = useIntl();

  return (
    <MConnectWalletButton onClick={onPresentConnectModal} {...props}>
      {intl.formatMessage({ id: "connect-wallet", defaultMessage: "Connect Wallet" })}
    </MConnectWalletButton>
  )
}

export default ConnectWalletButton
