import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { ModalStyled, ModalBodyStyled, Flex, useModal } from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
import useActiveLoginReact from 'hooks/useActiveLoginReact'
import useMiddlewareTranslation from 'hooks/useMiddlewareTranslation'
import { useCookies } from 'react-cookie';
import { ButtonStyled, GeneralText, TextFieldWithIconStyled } from 'components/GlobalStyle'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap';
import ConnectWalletButton from 'components/ConnectWalletButton'
import { parseJwt } from 'utils';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { axiosGet, axiosPost } from "utils/callAPI"
import FixedPageCenterLoader from 'components/Loader/FixedPageCenterLoader'
import { ElasticEase } from '@babylonjs/core'

// const SignInBackground = styled(Flex) <{ imagePath: string }>`
//   flex-direction:column!important;
//   background:url('${({ imagePath }) => imagePath}')!important;
//   background-repeat:no-repeat!important;
//   background-position:left!important;
//   background-size: 24px 24px!important;
//   width:100%;
//   @media only screen and (max-width: 400px){
// }
// `
const CurrentLifeCycle = `${process.env.REACT_APP_RELEASE_LIFECYCLE}`.toLowerCase()

type SignUpWithSocialMediaModalProps = {
  onDismiss: () => void,
};

const SignUpWithSocialMediaModal = (props: SignUpWithSocialMediaModalProps) => {
  const { onDismiss } = props;
  const intl = useIntl();
  const { onGetTranslation } = useMiddlewareTranslation();
  const { toastError } = useToast();
  const [externalPopup, setExternalPopup] = useState(null);
  const [cookie, setCookie, removeCookie] = useCookies();
  const [connectIsLoading, setConnectIsLoading] = useState(false);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        clearInterval(timer);
        return;
      }
      if (externalPopup.closed) {
        setConnectIsLoading(false);
        clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const { searchParams } = new URL(currentUrl);
      try {
        const jwtToken = searchParams.get('token');
        const checkError = searchParams.get('error');
        if (jwtToken) {
          setConnectIsLoading(false);
          externalPopup.close();
          const decodeJwt = parseJwt(jwtToken);
          const expiresJwt = new Date(decodeJwt.exp * 1000);
          setCookie(
            '_account',
            JSON.stringify({ token: jwtToken, iat: decodeJwt.iat, exp: decodeJwt.exp, walletAddress: decodeJwt.walletAddress, uuid: decodeJwt.uuid, loginType: decodeJwt.loginType, hash: decodeJwt.hash }),
            {
              path: '/',
              expires: expiresJwt,
              // maxAge: 1000,
              secure: true,
              httpOnly: true,
            }
          )
          onDismiss();

          // YourApi.endpoint(code).then(() => {
          // change UI to show after the code was stored
          // })
          // .catch(() => {
          // API error
          // })
          // .finally(() => {
          // clear timer at the end
          setExternalPopup(null);
          // timer && 
          clearInterval(timer);
          // })
        }
        if (checkError) {
          const buff = Buffer.from(checkError, 'base64');
          throw new Error(buff.toString('ascii'));
        }
      } catch (e: any) {
        externalPopup.close();
        console.error(e);
        toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
        setConnectIsLoading(false);
      }

    }, 500)
  },
    [externalPopup, intl, onDismiss, onGetTranslation, setCookie, toastError]
  )

  const handleConnectWithSocialMediaOnClick = (e: React.MouseEvent<HTMLAnchorElement>, connectType: string) => {
    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = connectType;
    const popup = window.open(`${process.env.REACT_APP_DOMAIN_LINK}auth/${connectType}`, title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
    setConnectIsLoading(true);
  }
  return (
    <ModalStyled title={intl.formatMessage({ id: "sign-in-via-social-media", defaultMessage: "Sign in via Social Media" })} onDismiss={onDismiss}>
      <ModalBodyStyled style={{ overflowY: "hidden", alignContent: "center" }}>
        <Flex flexDirection="column" >

          <ButtonStyled
            bgColor="white"
            width="100%"
            height="45px"
            style={{ margin: "0px 0px 25px 0px", border: "none" }}
            onClick={(e) => handleConnectWithSocialMediaOnClick(e, "Google")}
          >
            <Flex justifyContent="start" flexWrap="nowrap" width="260px">
              <Flex flexDirection="column" alignSelf="center">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" height="24" width="24" ><path fill="#4285F4" d="M20.822 12.207c0-.612-.05-1.227-.155-1.829h-8.49v3.466h4.862a4.166 4.166 0 01-1.8 2.735v2.248h2.901c1.703-1.567 2.682-3.882 2.682-6.62z" /><path fill="#34A853" d="M12.178 21c2.427 0 4.474-.797 5.965-2.173l-2.9-2.248c-.807.549-1.848.86-3.062.86-2.348 0-4.339-1.584-5.053-3.714H4.135v2.318A9 9 0 0012.178 21z" /><path fill="#FBBC04" d="M7.125 13.725a5.39 5.39 0 010-3.446V7.961h-2.99a9.007 9.007 0 000 8.082l2.99-2.318z" /><path fill="#EA4335" d="M12.178 6.562a4.89 4.89 0 013.452 1.35l2.57-2.57A8.65 8.65 0 0012.178 3a8.998 8.998 0 00-8.043 4.96l2.99 2.318c.71-2.133 2.705-3.717 5.053-3.717z" /></svg>
              </Flex>
              <GeneralText color="var(--blackColor)" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={500} className="" style={{ padding: "0px 24px", alignSelf: "center" }}>
                {intl.formatMessage({ id: "continue-with-google", defaultMessage: "Continue with Google" })}
              </GeneralText>
            </Flex>
          </ButtonStyled>

          <ButtonStyled
            bgColor="white"
            width="100%"
            height="45px"
            style={{ margin: "0px 0px 25px 0px", border: "none" }}
            onClick={(e) => handleConnectWithSocialMediaOnClick(e, "Facebook")}
          >
            <Flex justifyContent="start" flexWrap="nowrap" width="260px">
              <Flex flexDirection="column" alignSelf="center">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" height="24" width="24" ><path fill="#1877F2" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" /><path fill="#fff" d="M15.893 14.89l.443-2.89h-2.773v-1.876c0-.79.387-1.562 1.63-1.562h1.26v-2.46s-1.144-.196-2.238-.196c-2.285 0-3.777 1.385-3.777 3.89V12h-2.54v2.89h2.54v6.988a10.072 10.072 0 003.124 0v-6.987h2.33z" /></svg>
              </Flex>
              <GeneralText color="var(--blackColor)" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={500} className="" style={{ padding: "0px 24px", alignSelf: "center" }}>
                {intl.formatMessage({ id: "continue-with-facebook", defaultMessage: "Continue with Facebook" })}
              </GeneralText>
            </Flex>
          </ButtonStyled>
          {/* 
          <ButtonStyled
            bgColor="white"
            width="100%"
            height="45px"
            style={{ margin: "0px 0px 25px 0px", border: "none" }}
            onClick={(e) => handleConnectWithSocialMediaOnClick(e, "Twitter")}
          >
            <Flex justifyContent="start" flexWrap="nowrap" width="260px">
              <Flex flexDirection="column" alignSelf="center">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" height="24" width="24"><path fill="#1DA1F2" d="M8.29 20c7.547 0 11.675-6.156 11.675-11.495 0-.175 0-.349-.012-.522A8.278 8.278 0 0022 5.892a8.291 8.291 0 01-2.357.635 4.066 4.066 0 001.804-2.234 8.302 8.302 0 01-2.605.98 4.127 4.127 0 00-2.328-1.22 4.163 4.163 0 00-2.602.425 4.07 4.07 0 00-1.803 1.896 3.986 3.986 0 00-.26 2.584 11.793 11.793 0 01-4.684-1.225 11.618 11.618 0 01-3.773-2.996 3.989 3.989 0 00-.45 2.944 4.044 4.044 0 001.72 2.45A4.122 4.122 0 012.8 9.623v.052c0 .932.328 1.836.928 2.558a4.117 4.117 0 002.364 1.402 4.16 4.16 0 01-1.853.07 4.047 4.047 0 001.46 2.007 4.15 4.15 0 002.374.798A8.316 8.316 0 012 18.185a11.75 11.75 0 006.29 1.812" /></svg>
              </Flex>
              <GeneralText color="var(--blackColor)" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={500} className="" style={{ padding: "0px 24px", alignSelf: "center" }}>
                {intl.formatMessage({ id: "continue-with-twitter", defaultMessage: "Continue with Twitter" })}
              </GeneralText>
            </Flex>
          </ButtonStyled> */}

          <ButtonStyled
            bgColor="white"
            width="100%"
            height="45px"
            style={{ margin: "0px 0px 0px 0px", border: "none" }}
            onClick={(e) => handleConnectWithSocialMediaOnClick(e, "Discord")}
          >
            <Flex justifyContent="start" flexWrap="nowrap" width="260px">
              <Flex flexDirection="column" alignSelf="center">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" >
                  <path d="M20.33 1.556C18.777 0.829 17.116 0.301 15.379 0C15.166 0.386 14.916 0.904 14.745 1.317C12.898 1.039 11.069 1.039 9.257 1.317C9.085 0.904 8.831 0.386 8.615 0C6.877 0.301 5.214 0.831 3.66 1.56C0.527 6.295 -0.323 10.913 0.102 15.465C2.18 17.017 4.194 17.959 6.175 18.576C6.663 17.904 7.1 17.188 7.475 16.434C6.76 16.163 6.075 15.827 5.427 15.438C5.599 15.31 5.767 15.177 5.929 15.04C9.878 16.887 14.169 16.887 18.071 15.04C18.235 15.177 18.403 15.31 18.573 15.438C17.923 15.829 17.236 16.164 16.521 16.436C16.897 17.188 17.331 17.905 17.822 18.578C19.804 17.961 21.82 17.019 23.898 15.465C24.396 10.188 23.047 5.612 20.33 1.556ZM8.013 12.665C6.828 12.665 5.856 11.558 5.856 10.211C5.856 8.863 6.807 7.754 8.013 7.754C9.219 7.754 10.192 8.861 10.171 10.211C10.173 11.558 9.219 12.665 8.013 12.665ZM15.987 12.665C14.801 12.665 13.829 11.558 13.829 10.211C13.829 8.863 14.78 7.754 15.987 7.754C17.193 7.754 18.165 8.861 18.144 10.211C18.144 11.558 17.193 12.665 15.987 12.665Z" fill="#5865F2" fillRule="nonzero" />
                </svg>
              </Flex>
              <GeneralText color="var(--blackColor)" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={500} className="" style={{ padding: "0px 24px", alignSelf: "center" }}>
                {intl.formatMessage({ id: "continue-with-discord", defaultMessage: "Continue with Discord" })}
              </GeneralText>
            </Flex>
          </ButtonStyled>

        </Flex>
      </ModalBodyStyled>
      {
        connectIsLoading && (
          <FixedPageCenterLoader />
        )}
    </ModalStyled >

  )
}

type SignUpWithEmailModalProps = {
  onDismiss: () => void,
};

const SignUpWithEmailModal = (props: SignUpWithEmailModalProps) => {
  const { onDismiss } = props;
  const intl = useIntl();
  const { onGetTranslation } = useMiddlewareTranslation();
  const formSchema = Yup.object().shape({
    username: Yup.string()
      .required(intl.formatMessage({ id: "username-is-required", defaultMessage: "Username is required" }))
      .max(100, intl.formatMessage({ id: "username-too-long", defaultMessage: "username too long" })),
    email: Yup.string()
      .required(intl.formatMessage({ id: "email-is-required", defaultMessage: "Email is required" }))
      .matches(/^\S+@\S+$/i, intl.formatMessage({ id: "invalid-email-address", defaultMessage: "Invalid email address" })),
    password: Yup.string()
      .required(intl.formatMessage({ id: "password-is-required", defaultMessage: "Password is required" }))
      .min(8, intl.formatMessage({ id: "password-length-should-be-at-least-8-characters", defaultMessage: "Password length should be at least 8 characters" })),
    confirmpassword: Yup.string()
      .required(intl.formatMessage({ id: "confirm-password-is-required", defaultMessage: "Confirm password is required" }))
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: "password-match-and-should-match", defaultMessage: "Passwords must and should match" })),
  })
  const validationOpt = { resolver: yupResolver(formSchema) }
  const { Group } = { ...Form }
  const { register, handleSubmit, formState: { errors } } = useForm(validationOpt)
  const { toastSuccess, toastError } = useToast();
  const [onLoginModal] = useModal(<LoginModal onDismiss={() => { /* do nothing */ }} />)
  const [signUpIsLoading, setSignUpIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setSignUpIsLoading(true);
      const params = {
        "email": data.email,
        "password": data.password,
        "username": data.username,
        "passwordVerify": data.confirmpassword
      }
      const response = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/register`, params, { validateStatus: () => true });
      if (response.error === true) {
        toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(response.message));
      } else if (response.error === false) {
        if (response.message !== 'OK') {
          toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(response.message));
        } else {
          toastSuccess(intl.formatMessage({ id: "sign-up-successfully", defaultMessage: "Sign Up Successfully" }), intl.formatMessage({ id: "please-proceed-to-login-thanks-you-for-sign-up", defaultMessage: "Please proceed to login now. Thank you for signing up." }));
          onDismiss();
          onLoginModal();
        }
      }
      setSignUpIsLoading(false);
    } catch (e: any) {
      console.error(e);
      toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
      setSignUpIsLoading(false);
    }
  }

  return (
    <ModalStyled title={intl.formatMessage({ id: "sign-up", defaultMessage: "Sign Up" })} onDismiss={onDismiss}>
      <ModalBodyStyled style={{ overflowY: "hidden", alignContent: "center" }}>
        <Flex flexDirection="column" >
          <GeneralText color="white" fontFamily="var(--fontFamily4)" weight={700} fontSize="0.875rem" style={{ margin: "0px 0px 10px 0px" }}>{intl.formatMessage({ id: "please-complete-the-registration-form", defaultMessage: "Please complete the registration form." })}</GeneralText>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Group>
              <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                <img src='/images/icon-username.png' className="" alt='logo' style={{ width: '20px', height: "20px", margin: "3px 10px" }} />
                <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                  <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                </svg>
                <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="username" type="text" autoCapitalize="off" autoComplete="off" autoCorrect='off' role="presentation" placeholder={`${intl.formatMessage({ id: "enter-your-username", defaultMessage: "Enter Your Username" })}`}{...register("username", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-password-to-continue", defaultMessage: "Fill in password to continue" })
                  }
                })} />
                {/* <Control type="password" placeholder='********' {...register("password", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-password-to-continue", defaultMessage: "Fill in password to continue" })
                  },
                  // pattern: {
                  //   value: /^?=.*?[A-Z]/i,
                  //   message: t('Password format not valid')
                  // }
                })} /> */}
              </Flex>
            </Group>
            <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
              {errors.username?.message}
            </GeneralText>

            <Group style={{ marginTop: '10px' }}>
              <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                <img src='/images/icon-email.png' className="" alt='logo' style={{ width: '20px', height: "15px", margin: "3px 10px" }} />
                <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                  <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                </svg>
                <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="email" autoCapitalize="off" autoComplete="off" autoCorrect='off' role="presentation" placeholder={`${intl.formatMessage({ id: "enter-your-email", defaultMessage: "Enter Your Email" })}`}{...register("email", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-email-to-continue", defaultMessage: "Fill in the email to continue" })
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: intl.formatMessage({ id: "email-not-valid", defaultMessage: "Email not valid" })
                  }
                })} />
              </Flex>
            </Group>
            <GeneralText color="red" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={700}>
              {errors.email?.message}
            </GeneralText>

            <Group style={{ marginTop: '10px' }}>
              <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                <img src='/images/icon-password.png' className="" alt='logo' style={{ width: '20px', height: "20px", margin: "3px 10px" }} />
                <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                  <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                </svg>
                <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="password" type="password" autoCapitalize="off" autoComplete="off" autoCorrect='off' role="presentation" placeholder={`${intl.formatMessage({ id: "enter-your-password", defaultMessage: "Enter Your Password" })}`}{...register("password", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-password-to-continue", defaultMessage: "Fill in password to continue" })
                  }
                })} />
                {/* <Control type="password" placeholder='********' {...register("password", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-password-to-continue", defaultMessage: "Fill in password to continue" })
                  },
                  // pattern: {
                  //   value: /^?=.*?[A-Z]/i,
                  //   message: t('Password format not valid')
                  // }
                })} /> */}
              </Flex>
            </Group>
            <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
              {errors.password?.message}
            </GeneralText>

            <Group style={{ marginTop: '10px' }}>
              <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                <img src='/images/icon-confirm-password.png' className="" alt='logo' style={{ width: '20px', height: "20px", margin: "3px 10px" }} />
                <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                  <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                </svg>
                <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="confirmpassword" type="password" autoCapitalize="off" autoComplete="off" autoCorrect='off' role="presentation" placeholder={`${intl.formatMessage({ id: "confirm-your-password", defaultMessage: "Confirm Your Password" })}`}{...register("confirmpassword", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-password-to-continue", defaultMessage: "Fill in password to continue" })
                  }
                })} />
                {/* <Control type="password" name="confirmpassword" placeholder='********' {...register("confirmpassword", {
                })} /> */}
              </Flex>
            </Group>
            <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
              {errors.confirmpassword?.message}
            </GeneralText>

            <Flex justifyContent="center" style={{ marginTop: "25px" }}>
              <ButtonStyled
                bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)"
                width="100%"
                height="45px"
              >
                <GeneralText color="var(--blackColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="" >
                  {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
                </GeneralText>
              </ButtonStyled>
            </Flex>
          </form>
        </Flex>
      </ModalBodyStyled>
      {
        signUpIsLoading && (
          <FixedPageCenterLoader />
        )}
    </ModalStyled >

  )
}

type ForgotPasswordModalProps = {
  onDismiss: () => void,
};

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
  const { onDismiss } = props;
  const intl = useIntl();
  const { onGetTranslation } = useMiddlewareTranslation();
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: "email-is-required", defaultMessage: "Email is required" }))
      .matches(/^\S+@\S+$/i, intl.formatMessage({ id: "invalid-email-address", defaultMessage: "Invalid email address" })),
  })
  const validationOpt = { resolver: yupResolver(formSchema) }
  const { Group } = { ...Form }
  const { register, handleSubmit, formState: { errors } } = useForm(validationOpt)
  const { toastSuccess, toastError } = useToast();
  const [forgotPasswordIsLoading, setForgotPasswordIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setForgotPasswordIsLoading(true);
      const params = {
        "email": data.email
      }
      const response = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/forgotPassword`, params, { validateStatus: () => true });
      if (response.error === true) {
        toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(response.message));
      } else if (response.error === false) {
        if (response.message !== 'OK') {
          toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(response.message));
        } else {
          toastSuccess(intl.formatMessage({ id: "success", defaultMessage: "Success" }), intl.formatMessage({ id: "request-reset-password-please-check-your-mailbox", defaultMessage: "Request reset password successfully. Please check your mailbox now." }));
          onDismiss();
        }
      }
      setForgotPasswordIsLoading(false);
    } catch (e: any) {
      console.error(e);
      toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
      setForgotPasswordIsLoading(false);
    }
  }

  return (
    <ModalStyled title={`${intl.formatMessage({ id: "forgot-password", defaultMessage: "Forgot Password" })}?`} onDismiss={onDismiss}>
      <ModalBodyStyled style={{ maxWidth: "500px", overflowY: "hidden" }}>
        <Flex flexDirection="column" style={{}}>
          <GeneralText color="white" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={700} >
            {
              intl.formatMessage({ id: "please-enter-your-email-address-to-receive-email", defaultMessage: "Please enter your email address. You will receive an email message with instructions on how to reset your password." })
            }
          </GeneralText>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Group style={{ margin: "20px 0px 0px 0px" }}>
              <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                <img src='/images/icon-email.png' className="" alt='logo' style={{ width: '20px', height: "15px", margin: "3px 10px" }} />
                <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                  <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                </svg>
                <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="email" autoCapitalize="off" autoComplete="off" autoCorrect='off' role="presentation" placeholder={`${intl.formatMessage({ id: "enter-your-email", defaultMessage: "Enter Your Email" })}`}{...register("email", {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "fill-in-email-to-continue", defaultMessage: "Fill in the email to continue" })
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: intl.formatMessage({ id: "email-not-valid", defaultMessage: "Email not valid" })
                  }
                })} />
              </Flex>
            </Group>
            <GeneralText color="red" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={700}>
              {errors.email?.message}
            </GeneralText>

            <Flex justifyContent="center" style={{ margin: "20px 0px 0px 0px" }}>
              <ButtonStyled
                bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)"
                width="100%"
                height="45px"
              >
                <GeneralText color="var(--blackColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="" >
                  {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
                </GeneralText>
              </ButtonStyled>
            </Flex>
          </form>
        </Flex>
      </ModalBodyStyled>
      {
        forgotPasswordIsLoading && (
          <FixedPageCenterLoader />
        )}
    </ModalStyled >

  )
}

type UserGuidanceTaskListProps = {
  onDismiss: () => void,
};

const UserGuidanceTaskListModal = (props: UserGuidanceTaskListProps) => {
  const { onDismiss } = props;
  const intl = useIntl();
  const { token, uuid } = useActiveLoginReact();
  // const { taskList } = useCheckTaskList();
  const { onGetTranslation } = useMiddlewareTranslation();
  const { toastSuccess, toastError } = useToast();
  const [isDontShowAgain, setDontShowAgain] = useState<boolean>(false);
  const [taskList] = useState<any>(() => {
    if (localStorage.getItem("_user_task_list")
      && localStorage.getItem("_user_task_list") !== "undefined") {
      return JSON.parse(localStorage.getItem("_user_task_list"));
    }
    return [{ task_id: 0, task_name: "", task_description: "", is_done: false }]
  });
  // useEffect(() => {
  //   async function getTaskList() {
  //     try {
  //       const config = {
  //         headers: {
  //           authorization: token
  //         },
  //         validateStatus: () => true
  //       }
  //       const getTaskListResponse = await axiosGet(`${process.env.REACT_APP_DOMAIN_LINK}auth/tasks`, config);
  //       if (getTaskListResponse.error === true || getTaskListResponse.message !== "OK") {
  //         throw new Error(getTaskListResponse.message);
  //       }
  //       setTaskList(getTaskListResponse.data.tasks);
  //     } catch (e: any) {
  //       console.error(e);
  //       toastError(intl.formatMessage({id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
  //     }
  //   }
  //   if (token)
  //     getTaskList();
  // }, [token, intl, onGetTranslation, toastError])

  return (
    <ModalStyled title={`${intl.formatMessage({ id: "task-list", defaultMessage: "Task List" })}`} onDismiss={onDismiss}>
      <ModalBodyStyled >
        <Flex flexDirection="column" style={{ gap: "20px" }}>
          <Flex flexDirection="column" >
            {/* <ul> */}
            {
              taskList
                .sort((a, b) => Number(a.order) - Number(b.order))
                .map((task: any) => {
                  return (
                    // <li key={task.task_id}>
                    <Flex justifyContent="space-between" alignItems="center" key={task.task_key}>
                      <GeneralText color="white" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={700} style={{ width: "90%" }} >
                        {onGetTranslation(task.task_name)}
                      </GeneralText>
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" style={{ width: "1em", height: "1em", verticalAlign: "middle", fill: "white", overflow: "hidden" }} viewBox="0 0 1024 1024" version="1.1">
                        <path d="M768 128a128 128 0 0 1 127.786667 120.490667L896 256v512a128 128 0 0 1-120.490667 127.786667L768 896H256a128 128 0 0 1-127.786667-120.490667L128 768V256a128 128 0 0 1 120.490667-127.786667L256 128h512z m0 64H256A64 64 0 0 0 192 256v512A64 64 0 0 0 256 832h512a64 64 0 0 0 64-64V256A64 64 0 0 0 768 192z" fill="white" />
                        {
                          task.is_done === true ?
                            <path d="M705.28 406.613333l-225.834667 225.834667a74.666667 74.666667 0 0 1-105.557333 0L297.386667 555.946667a32 32 0 0 1 45.226666-45.226667l76.501334 76.501333a10.666667 10.666667 0 0 0 15.104 0L660.053333 361.386667a32 32 0 0 1 45.226667 45.226666z" fill="white" />
                            :
                            ""
                        }
                      </svg>
                    </Flex>
                    // </li>
                  )
                })
            }
            {/* </ul> */}
          </Flex>

          <Flex flexDirection="column" style={{ gap: "5px" }}>
            <ButtonStyled
              bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)"
              width="100%"
              height="45px"
              onClick={() => {
                if (isDontShowAgain) {
                  const futureDate = new Date();
                  futureDate.setUTCHours(24, 0, 0, 0);
                  futureDate.setUTCDate(futureDate.getUTCDate()); // add 12am tmr timestamp
                  const futureTimestamp = futureDate.getTime();
                  localStorage.setItem(`_${uuid}_is_dont_show_task_list_again`, JSON.stringify(futureTimestamp));
                }
                onDismiss();
              }}
            >
              <GeneralText color="var(--blackColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="" >
                {intl.formatMessage({ id: "ok", defaultMessage: "OK" })}
              </GeneralText>
            </ButtonStyled>
            <Flex style={{ gap: "10px", width: "fill-available", alignSelf: "center" }}>
              <input type="checkbox" id="dont-show-me" name="dont-show-me" value="checked"
                onChange={(e) => {
                  if (e.target.value === "checked")
                    setDontShowAgain(true);
                  else
                    setDontShowAgain(false);
                }} style={{ width: "fit-content" }} />
              <GeneralText color="var(--neonBLightColor)" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={500} className=""  >
                <label htmlFor="dont-show-me">{`${intl.formatMessage({ id: "dont-show-me-this-again-today", defaultMessage: "Don't show me this again today" })}`}</label>
              </GeneralText>
            </Flex>
          </Flex>
        </Flex>
      </ModalBodyStyled>
    </ModalStyled >
  )
}
type LoginModalProps = {
  onDismiss: () => void,
};

const LoginModal = (props: LoginModalProps) => {
  const { onDismiss } = props;

  const intl = useIntl();
  const { Group } = { ...Form }
  const { register, handleSubmit, formState: { errors } } = useForm()
  const { toastError } = useToast();
  const { onGetTranslation } = useMiddlewareTranslation();
  const [cookie, setCookie, removeCookie] = useCookies();
  const [onSignUpWithEmailModal] = useModal(<SignUpWithEmailModal onDismiss={() => { /* do nothing */ }} />)
  const [onForgotPasswordModal] = useModal(<ForgotPasswordModal onDismiss={() => { /* do nothing */ }} />)
  const [onSignUpWithSocialMediaModal] = useModal(<SignUpWithSocialMediaModal onDismiss={() => { /* do nothing */ }} />)
  const [onUserGuidanceTaskListModal] = useModal(<UserGuidanceTaskListModal onDismiss={() => { /* do nothing */ }} />)
  const [loginIsLoading, setLoginIsLoading] = useState(false);

  const checkTaskList = async (token) => {
    try {
      const config = {
        headers: {
          authorization: token
        },
        validateStatus: () => true
      }
      // Get user task list
      const getTaskListResponse = await axiosGet(`${process.env.REACT_APP_DOMAIN_LINK}auth/tasks`, config);
      if (getTaskListResponse.error === true || getTaskListResponse.message !== "OK") {
        throw new Error(getTaskListResponse.message);
      }
      if (localStorage)
        localStorage.setItem("_user_task_list", JSON.stringify(getTaskListResponse.data.tasks));
      if (localStorage.getItem("_user_task_list")) {
        const taskList = JSON.parse(localStorage.getItem("_user_task_list"));
        if (taskList) {
          // Check if user has publish scene task before
          const taskPublishSceneIndex = taskList.findIndex((item) =>
            item.task_key === "publish-scene"
          );
          const taskCreateSceneIndex = taskList.findIndex((item) =>
            item.task_key === "create-scene"
          );
          if (taskList[taskPublishSceneIndex].is_done === false) {
            try {
              const checkSceneIsPublicResponse = await axiosGet(`${process.env.REACT_APP_DOMAIN_LINK}scene/hasPublished`, config);
              if (checkSceneIsPublicResponse.error === true || checkSceneIsPublicResponse.message !== "OK") {
                throw new Error(checkSceneIsPublicResponse.message);
              }
              if (checkSceneIsPublicResponse.data.hasPublished === true) {
                // If user has published scene, set publish scene task to done
                try {
                  const setTaskParam = {
                    task_key: taskList[taskPublishSceneIndex].task_key,
                    done: 1
                  }
                  const setTaskListResponse = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/tasks`, setTaskParam, config);
                  if (setTaskListResponse.error === true || (setTaskListResponse.message !== "OK")) {
                    throw new Error(setTaskListResponse.message);
                  }
                  taskList[taskPublishSceneIndex].is_done = true;
                  localStorage.setItem("_user_task_list", JSON.stringify(taskList));
                } catch (e: any) {
                  console.log(e);
                }
              }
            }
            catch (e: any) {
              console.error(e);
            }
          }

          if (taskList[taskPublishSceneIndex].is_done === true || taskList[taskCreateSceneIndex].is_done === false) {
            // If user has published scene, set create scene task to done
            try {
              const setTaskParam = {
                task_key: taskList[taskCreateSceneIndex].task_key,
                done: 1
              }
              const setTaskListResponse = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/tasks`, setTaskParam, config);
              if (setTaskListResponse.error === true || (setTaskListResponse.message !== "OK")) {
                throw new Error(setTaskListResponse.message);
              }
              taskList[taskCreateSceneIndex].is_done = true;
              localStorage.setItem("_user_task_list", JSON.stringify(taskList));
            } catch (e: any) {
              console.log(e);
            }

          }

          // Check if user not done all task then show task list modal
          taskList.forEach((task) => {
            if (task.is_done === false) {
              onUserGuidanceTaskListModal();
            }
          });
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  }
  const onSubmit = async (data) => {
    try {
      setLoginIsLoading(true);
      const params = {
        'email': data.email,
        'password': data.password
      }
      const response = await axiosPost(`${process.env.REACT_APP_DOMAIN_LINK}auth/login`, params, { validateStatus: () => true });
      if (response.error === true) {
        toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(response.message));
      } else if (response.error === false) {
        if (response.message !== 'OK') {
          toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(response.message));
        } else {
          const decodeJwt = parseJwt(response.data.token);
          const expiresJwt = new Date(decodeJwt.exp * 1000);
          setCookie(
            '_account',
            JSON.stringify({ token: response.data.token, iat: decodeJwt.iat, exp: decodeJwt.exp, walletAddress: decodeJwt.walletAddress, uuid: decodeJwt.uuid, loginType: decodeJwt.loginType, hash: decodeJwt.hash }),
            {
              path: '/',
              expires: expiresJwt,
              // maxAge: 1000,
              // secure: true,
              // httpOnly: true,
            }
          )
          onDismiss();
          // if local storage is supported and user has click "Don't show again today" before
          if (localStorage && localStorage.getItem(`_${decodeJwt.uuid}_is_dont_show_task_list_again`) !== null) {
            const prevIsDontShowAgainTimestamp = JSON.parse(localStorage.getItem(`_${decodeJwt.uuid}_is_dont_show_task_list_again`));
            const nowTimestamp = Date.now();
            // if current time is greater than or equal to the time when user click "Don't show again today" before, else do nothing
            if (nowTimestamp >= prevIsDontShowAgainTimestamp) {
              localStorage.removeItem(`_${decodeJwt.uuid}_is_dont_show_task_list_again`);
              checkTaskList(response.data.token);
            }
          } else {
            checkTaskList(response.data.token);
          }
          // toastSuccess("Successfully login!");
        }
      }
      setLoginIsLoading(false);
    } catch (e: any) {
      console.error(e);
      toastError(intl.formatMessage({ id: "failed", defaultMessage: "Failed" }), onGetTranslation(e.message));
      setLoginIsLoading(false);
    }
  }

  return (
    <>
      <ModalStyled title={CurrentLifeCycle === "alpha" ? intl.formatMessage({ id: "login", defaultMessage: "Login" }) : intl.formatMessage({ id: "login-or-create-an-account", defaultMessage: "Login Or Create An Account" })} onDismiss={onDismiss}>
        <ModalBodyStyled style={{ maxWidth: "500px", overflowY: "hidden", alignContent: "center" }}>
          <Flex flexDirection="column">
            {
              CurrentLifeCycle === "alpha" ?
                ""
                :
                <Flex flexDirection="column" style={{ gap: "25px", margin: "0px 0px 25px 0px" }}>
                  <Flex flexDirection="column" style={{ gap: "10px" }}>
                    <GeneralText color="white" fontFamily="var(--fontFamily4)" weight={500} fontSize="1rem" style={{ textAlign: "center" }}>
                      {intl.formatMessage({ id: "sign-in-sign-up-with-wallet", defaultMessage: "Sign in / Sign up with Wallet" })}
                    </GeneralText>
                    <ConnectWalletButton className="" />
                  </Flex>
                  <GeneralText color="white" fontFamily="var(--fontFamily4)" weight={500} fontSize="1rem" style={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "or", defaultMessage: "Or" })}
                  </GeneralText>
                </Flex>
            }

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Flex flexDirection="column" style={{ gap: "25px" }}>
                <Flex flexDirection="column" style={{ gap: "10px" }}>
                  <GeneralText color="white" fontFamily="var(--fontFamily4)" weight={500} fontSize="1rem" style={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "sign-in-with-email", defaultMessage: "Sign in with Email" })}
                  </GeneralText>
                  <Flex flexDirection="column">
                    <Group style={{ margin: '10px 0px 0px 0px', background: "black" }}>
                      <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                        <img src='/images/icon-email.png' className="" alt='logo' style={{ width: '20px', height: "15px", margin: "3px 10px" }} />
                        <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                          <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                        </svg>
                        <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="email" role="presentation" placeholder={`${intl.formatMessage({ id: "enter-your-email", defaultMessage: "Enter Your Email" })}`}{...register("email", {
                          required: {
                            value: true,
                            message: intl.formatMessage({ id: "fill-in-email-to-continue", defaultMessage: "Fill in the email to continue" })
                          },
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: intl.formatMessage({ id: "email-not-valid", defaultMessage: "Email not valid" })
                          }
                        })} />
                      </Flex>
                    </Group>
                    <GeneralText color="red" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={700}>
                      {errors.email?.message}
                    </GeneralText>
                  </Flex>
                  <Flex flexDirection="column">
                    <Group>
                      <Flex flexDirection="row" alignItems="center" style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "5px" }}>
                        <img src='/images/icon-password.png' className="" alt='logo' style={{ width: '20px', height: "20px", margin: "3px 10px" }} />
                        <svg height="40px" width="2px" style={{ margin: "0px 10px 0px 0px" }}>
                          <line x1="0" y1="10" x2="0" y2="30" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: 2 }} />
                        </svg>
                        <TextFieldWithIconStyled style={{ width: "100%" }} className="input-custom" fontColor="white" bgColor="transparent" name="password" type="password" role="presentation" placeholder={`${intl.formatMessage({ id: "enter-your-password", defaultMessage: "Enter Your Password" })}`}{...register("password", {
                          required: {
                            value: true,
                            message: intl.formatMessage({ id: "fill-in-password-to-continue", defaultMessage: "Fill in password to continue" })
                          }
                        })} />
                      </Flex>
                    </Group>
                    <GeneralText color="red" fontSize="0.9em" fontFamily="var(--fontFamily4)" weight={700}>
                      {errors.password?.message}
                    </GeneralText>
                  </Flex>
                </Flex>
                <Flex flexDirection="column" style={{ alignItems: "center", textAlign: "center" }}>
                  {
                    CurrentLifeCycle === "alpha" ?
                      ""
                      :
                      <Flex flexWrap="wrap" justifyContent="center">
                        <GeneralText color="white" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={500} style={{ marginRight: "3px" }}>
                          {
                            intl.formatMessage({ id: "dont-have-an-account", defaultMessage: "Don't have an account?" })
                          }
                        </GeneralText>
                        <a href='/' onClick={(e) => {
                          e.preventDefault();
                          onSignUpWithEmailModal();
                        }} style={{ fontFamily: "var(--fontFamily4)", fontSize: "1rem", textDecoration: "underline", color: "var(--neonBLightColor)" }}>
                          {intl.formatMessage({ id: "register-here", defaultMessage: "Register Here" })}
                        </a>
                      </Flex>
                  }
                  <a href='/' onClick={(e) => {
                    e.preventDefault();
                    onForgotPasswordModal();
                  }} style={{ fontFamily: "var(--fontFamily4)", fontSize: "1rem", textDecoration: "underline", color: "var(--neonBLightColor)" }}>
                    {intl.formatMessage({ id: "forgot-password", defaultMessage: "Forgot Password" })}
                  </a>
                </Flex>
                <Flex justifyContent="center" style={{ margin: "0px 0px 10px 0px" }}>
                  <ButtonStyled
                    bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)"
                    width="100%"
                    height="45px"
                  >
                    <GeneralText color="var(--blackColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} className="" >
                      {intl.formatMessage({ id: "sign-in", defaultMessage: "Sign in" })}
                    </GeneralText>
                  </ButtonStyled>
                </Flex>
              </Flex>
            </form>
            {
              CurrentLifeCycle === "alpha" ?
                ""
                :
                <Flex justifyContent="center" style={{}}>
                  <a href='/' onClick={(e) => {
                    e.preventDefault();
                    onSignUpWithSocialMediaModal();
                  }} style={{ fontFamily: "var(--fontFamily4)", fontSize: "1rem", textDecoration: "underline", color: "var(--neonBLightColor)" }}>
                    {intl.formatMessage({ id: "sign-in-via-social-media", defaultMessage: "Sign in via Social Media" })}
                  </a>
                </Flex>
            }
          </Flex>
        </ModalBodyStyled>
        {
          loginIsLoading && (
            <FixedPageCenterLoader />
          )
        }
      </ModalStyled >
    </>
  )
}

export default LoginModal
