import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { axiosGet } from 'utils/callAPI';
import useRefresh from './useRefresh';
import useActiveLoginReact from './useActiveLoginReact'
import useAuthWithEmail from './useAuthWithEmail';

/**
 * Use this hook to check if the user token has expired.
 * Get the user details at the same time.
 * Check if the email is verified.
 */

const useActiveLoginIsAuth = () => {
  const [cookie, setCookie, removeCookie] = useCookies();
  const { token } = useActiveLoginReact();
  const { logoutAll } = useAuthWithEmail();
  const { slowRefresh } = useRefresh();
  const [userDetails, setUserDetails] = useState(
    () => {
      if (localStorage.getItem("_user_details")) {
        return (JSON.parse(localStorage.getItem("_user_details")));
      }
      return { address: "", avatar_profile_picture: "", email: "", is_email_verified: false, username: "", uuid: "" }
    });
  const [isRefresh, setAuthIsRefresh] = useState<number>(0)  // if want refresh the userDetails

  useEffect(() => {
    async function getUserDetails() {
      const config = {
        headers: {
          authorization: token
        },
        validateStatus: () => true
      }
      try {
        const getUserDetailsResponse = await axiosGet(`${process.env.REACT_APP_DOMAIN_LINK}auth/isAuthenticated`, config);
        if (getUserDetailsResponse.error === true || getUserDetailsResponse.message !== "OK") {
          throw new Error(getUserDetailsResponse.message);
        } else {
          if (localStorage) {
            localStorage.setItem(`_user_details`, JSON.stringify({ address: getUserDetailsResponse.data.address, avatar_profile_picture: `${getUserDetailsResponse.data.avatar_profile_picture || "./images/profile.png"}?v=${Date.now()}`, email: getUserDetailsResponse.data.email, is_email_verified: getUserDetailsResponse.data.is_email_verified || false, username: getUserDetailsResponse.data.username, uuid: getUserDetailsResponse.data.uuid }));
          }
          setUserDetails({ address: getUserDetailsResponse.data.address, avatar_profile_picture: `${getUserDetailsResponse.data.avatar_profile_picture || "./images/profile.png"}?v=${Date.now()}`, email: getUserDetailsResponse.data.email, is_email_verified: getUserDetailsResponse.data.is_email_verified || false, username: getUserDetailsResponse.data.username, uuid: getUserDetailsResponse.data.uuid });
        }
      } catch (e: any) {
        console.error(e);
        logoutAll();
      }
    }
    if (cookie._account) {
      if (token) {
        getUserDetails();
      }
    } else {
      setUserDetails({ address: "", avatar_profile_picture: "", email: "", is_email_verified: false, username: "", uuid: "" });
    }
  }, [cookie, slowRefresh, logoutAll, removeCookie, token, isRefresh])



  return { address: userDetails.address, avatarProfilePicture: userDetails.avatar_profile_picture, email: userDetails.email, isEmailVerified: userDetails.is_email_verified, username: userDetails.username, uuid: userDetails.uuid, setUserDetails, setAuthIsRefresh }
}

export default useActiveLoginIsAuth
