import axios from 'axios'

export const axiosPost = async (baseUrl: string, params?: any, config?: any) => {
  try {
    const response = await axios.post(baseUrl, params, config);
    const { data, error, msg, message, status } = await response?.data
    // if (error || status !== 200) throw new Error(msg)
    if (error || status !== 200) {
      console.error(response)
      return {
        error: true,
        data,
        status,
        message: msg || message,
      }
    }
    return {
      error: false,
      data,
      status,
      message: msg
    }
  } catch (e: any) {
    const { message = "" } = e
    console.error(e)
    return {
      error: true,
      data: {},
      status: "",
      message,
    }
  }
}

export const axiosGet = async (baseUrl: string, config?: any) => {
  try {
    const response = await axios.get(baseUrl, config);
    const { data, error, msg, message, status, code } = await response?.data
    // if (error || status !== 200) throw new Error(msg)
    if (error || (status && status !== 200) || (code && code !== 200)) {
      console.error(response)
      return {
        error: true,
        data,
        status,
        message: msg || message,
      }
    }
    return {
      error: false,
      data,
      status,
      message: msg || message
    }
  } catch (e: any) {
    const { message = "" } = e
    console.error(e)
    return {
      error: true,
      data: {},
      message
    }
  }
}

export const axiosPut = async (baseUrl: string, params?: any, config?: any) => {
  try {
    const response = await axios.put(baseUrl, params, config);
    const { data, error, msg, message, status } = await response?.data
    // if (error || status !== 200) throw new Error(msg)
    if (error || status !== 200) {
      console.error(response)
      return {
        error: true,
        data,
        status,
        message: msg || message,
      }
    }
    return {
      error: false,
      data,
      status,
      message: msg
    }
  } catch (e: any) {
    const { message = "" } = e
    console.error(e)
    return {
      error: true,
      data: {},
      status: "",
      message,
    }
  }
}
