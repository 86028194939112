import { useEffect, useState } from 'react';
// import { BigNumber } from '@ethersproject/bignumber'
import { BigNumber } from 'bignumber.js'
import useActiveLoginReact from './useActiveLoginReact';
import useRefresh from './useRefresh';
import useActiveWeb3React from './useActiveWeb3React';

function getCurrencyDetails(currency) {

  if (currency === 'eth') {
    return { contractAddress: "0x0000000000000000000000000000000000000000", isEther: true, totalDecimals: 1000000000000000000 };
  }
  return { contractAddress: '0', isEther: false, totalDecimals: 0 };
}
function useGetETHBalance() {
  const {  library, account } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();
  const accountInfo = useActiveLoginReact();
  const [ether, setEther] = useState('0');
  useEffect(() => {
    async function getEtherBalance() {
      await library.getBalance(accountInfo.walletAddress).then((etherBalance) => {
        const balanceBeforeDivide = new BigNumber(etherBalance.toString());
        setEther(((balanceBeforeDivide.toNumber() / getCurrencyDetails("eth").totalDecimals)).toFixed(4).toString());
      });
    }
    try {
      if (accountInfo.walletAddress) {
        getEtherBalance();
      } else {
        setEther('0');
      }
    } catch (e) {
      console.error(e);
    }
  }, [account, accountInfo, library, fastRefresh]);
  return ether
}

export default useGetETHBalance
