import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dropdown, Button, Flex, ModalStyled, ModalBodyStyled, useModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth';
import { useCookies } from 'react-cookie';
import { ButtonStyled, GeneralText } from 'components/GlobalStyle'
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useHistory } from 'react-router-dom'
import useAuthWithEmail from 'hooks/useAuthWithEmail';

const CurrentLifeCycle = `${process.env.REACT_APP_RELEASE_LIFECYCLE}`.toLowerCase()

type SignOutModalProps = {
  onDismiss: () => void
}

export const SignOutModal = (props: SignOutModalProps) => {
  const { onDismiss } = props;
  // const history = useHistory();
  const intl = useIntl();
  const { logoutAll } = useAuthWithEmail();

  const handleSignOutOnClick = async () => {
    logoutAll();
    onDismiss();
    window.location.href = "/profile";
    // history.push({
    //   pathname: '../profile'
    // });

  }

  return (
    <ModalStyled title={`${intl.formatMessage({ id: "sign-out", defaultMessage: "Sign out" })} `} onDismiss={onDismiss}>
      <ModalBodyStyled style={{ maxWidth: "500px", overflowY: "hidden" }}>
        <Flex flexDirection="column">
          <GeneralText color="white" weight={500} fontSize="1rem" fontFamily="var(--fontFamily4)" style={{ alignSelf: "center" }} >
            <FormattedMessage
              id='sign-out-from-arktory'
              defaultMessage="Sign out From Arktory?" />
          </GeneralText>

          <Flex flexDirection="row" justifyContent="center" style={{ margin: "25px 0px 0px 0px" }}>
            <ButtonStyled bgColor="var(--blackColor)" width="100%" height="45px" style={{
              color: 'var(--blackColor)', margin: "0px 5px 0px 0px"
            }} onClick={() => handleSignOutOnClick()} className="">
              <GeneralText color="var(--neonBLightColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700}>
                {intl.formatMessage({ id: "sign-out", defaultMessage: "Sign out" })}
              </GeneralText>
            </ButtonStyled>
            <ButtonStyled bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)" width="100%" height="45px" style={{
              color: 'var(--blackColor)', margin: "0px 5px 0px 0px"
            }} onClick={() => onDismiss()} className="">
              <GeneralText color="var(--blackColor)" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700}>
                {intl.formatMessage({ id: "stay", defaultMessage: "Stay" })}
              </GeneralText>
            </ButtonStyled>
          </Flex>
        </Flex>
      </ModalBodyStyled>
    </ModalStyled >
  )
}

const UserMenuSelector: React.FC = () => {
  const intl = useIntl();
  const { account } = useActiveWeb3React();
  const [onSignOutModal] = useModal(<SignOutModal onDismiss={() => {/* do nothing */ }} />);
  return <>
    <Dropdown
      target={
        <Button style={{ border: "none", background: "none", width: "fit-content", boxShadow: "none" }}>
          {
            account && CurrentLifeCycle !== "alpha" ?
              <Flex style={{ alignItems: "center", backgroundColor: "black", borderRadius: "16px", padding: "0px 5px 0px 0px" }}>
                <div style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "50%", padding: "2px" }}>
                  <i className="fa fa-user" aria-hidden="true" style={{ color: "var(--neonBLightColor)", margin: "3px" }} />
                </div>
                <GeneralText color="white" fontSize="1rem" fontFamily="var(--fontFamily4)" weight={700} style={{ padding: "0px 5px 0px 5px" }}>{account.substring(0, 4)}...{account.substring(account.length - 4)}</GeneralText>
              </Flex>
              :
              <div style={{ border: "2px solid var(--neonBLightColor)", borderRadius: "50%", padding: "1px" }}>
                <i className="fa fa-user" aria-hidden="true" style={{ color: "var(--neonBLightColor)", margin: "3px" }} />
              </div>
          }

        </Button>
      }
    >
      <Button
        key="signOut"
        onClick={() => {
          onSignOutModal();
          // if (localStorage) {
          //   localStorage.removeItem(`connectorIdv2`);
          // }
          // logout();
          // removeCookie(
          //   '_account'
          // );
        }}
        className="lang-btn"
      >
        <i className="fa fa-sign-out-alt" aria-hidden="true" style={{ margin: "3px" }} />
        {intl.formatMessage({ id: "sign-out", defaultMessage: "Sign out" })}
      </Button>
    </Dropdown>
  </>

};

export default UserMenuSelector;
