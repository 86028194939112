import React from 'react'
import { Text, Dropdown, Button, LanguageIcon, Language } from '@pancakeswap/uikit'

interface Props {
  currentLang: string;
  langs: Language[];
  setLang: (lang: Language) => void;
}

const LangSelector: React.FC<Props> = ({ currentLang, langs, setLang }) => (
  <Dropdown
    target={
      <Button variant="text" startIcon={<LanguageIcon className="" width="24px" style={{fill:"#01F3F6"}}/>}>
        <Text className="text-white" style={{ fontFamily: 'var(--fontFamily2)' }}>{currentLang?.toUpperCase()}</Text>
      </Button>
    }
  >
    {langs.map((lang) => (
      <Button
        key={lang.locale}
        onClick={() => setLang(lang)}
        className="lang-btn"
      >
        {lang.language}
      </Button>
    ))}
  </Dropdown>
);

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
