import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { connectorsByName } from 'utils/web3React'
import useRefresh from './useRefresh';

const useActiveLoginReact = () => {
  const [cookie] = useCookies();
  const [accountInfo, setAccountInfo] = useState(() => {
    if (cookie._account) {
      if (cookie._account.exp * 1000 < Date.now()) {
        if (localStorage) {
          localStorage.removeItem(`connectorIdv2`);
        }
        deactivate()
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
          connectorsByName.walletconnect.close()
          connectorsByName.walletconnect.walletConnectProvider = null
        }
        return ({ token: null, iat: null, exp: null, walletAddress: null, uuid: null, loginType: null, hash: null });
      }
      return cookie._account
    }
    return { token: null, iat: null, exp: null, walletAddress: null, uuid: null, loginType: null, hash: null }
  });
  const { fastRefresh } = useRefresh();
  const { deactivate } = useWeb3React();

  useEffect(() => {
    if (cookie._account) {
      // if (walletAddress === null && cookie._account.walletAddress !== null)
      setAccountInfo((cookie._account));
      // else {
      // setAccountInfo({ token: cookie._account.token, iat: cookie._account.iat, exp: cookie._account.exp, walletAddress: cookie._account.walletAddress, uuid: cookie._account.uuid, loginType: cookie._account.loginType, hash: cookie._account.hash });
      // }
      if (cookie._account.exp * 1000 < Date.now()) {
        if (localStorage) {
          localStorage.removeItem(`connectorIdv2`);
        }
        deactivate()
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
          connectorsByName.walletconnect.close()
          connectorsByName.walletconnect.walletConnectProvider = null
        }
        setAccountInfo({ token: null, iat: null, exp: null, walletAddress: null, uuid: null, loginType: null, hash: null });
      }
    } else {
      setAccountInfo({ token: null, iat: null, exp: null, walletAddress: null, uuid: null, loginType: null, hash: null });
    }
  }, [cookie, deactivate, fastRefresh])

  return { token: accountInfo.token, iat: accountInfo.iat, exp: accountInfo.exp, walletAddress: accountInfo.walletAddress, uuid: accountInfo.uuid, loginType: accountInfo.loginType, hash: accountInfo.hash }
}

export default useActiveLoginReact
