import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
}
export const BASE_ETHER_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://etherscan.com',
  [ChainId.TESTNET]: 'https://goerli.etherscan.io/',
}

export const BASE_URL = 'https://pancakeswap.finance' // need to change when got domain
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET]
export const BASE_ETHER_SCAN_URL = BASE_ETHER_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 1500000
export const DEFAULT_GAS_PRICE = 5
