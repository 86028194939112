import styled from 'styled-components'
import { Text, Button } from '@pancakeswap/uikit'

export const GeneralText = styled(Text)
  <{ color: string, fontSize: string, weight: number, fontFamily: string, fontStyle?: 'normal' }>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-style: ${({ fontStyle }) => fontStyle};
  font-weight: ${({ weight }) => weight};
  font-family: ${({ fontFamily }) => fontFamily};
  white-space: normal;
`

export const ButtonStyled = styled(Button) <{ bgColor: string, width: string, height: string }>`
  background: ${({ bgColor }) => bgColor};
  border: 2px solid #01F3F6;
  box-shadow: none;
  border-radius: 5px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`
export const TextFieldWithIconStyled = styled.input<{ fontColor: string, bgColor: string }>`
background-color:${({ bgColor }) => bgColor};
color:${({ fontColor }) => fontColor};
border:none;
type:text;
caret-color:white;
:focus{
  border:none;
  outline:none;
  background-color:${({ bgColor }) => bgColor};
}
:hover{
  border:none;
  outline:none;
  background-color:${({ bgColor }) => bgColor};
}
:active{
  border:none;
  outline:none;
  background-color:${({ bgColor }) => bgColor};
}
`

export const TextAreaWithIconStyled = styled.textarea<{ fontColor: string, bgColor: string }>`
background-color:${({ bgColor }) => bgColor};
color:${({ fontColor }) => fontColor};
border:none;
type:text;
caret-color:white;
resize: none;
:focus{
  border:none;
  outline:none;
  background-color:${({ bgColor }) => bgColor};
}
:hover{
  border:none;
  outline:none;
  background-color:${({ bgColor }) => bgColor};
}
:active{
  border:none;
  outline:none;
  background-color:${({ bgColor }) => bgColor};
}
`

