import React, { useCallback, useEffect } from 'react'
// import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import useActiveLoginReact from 'hooks/useActiveLoginReact'
import useGetETHBalance from 'hooks/useGetETHBalance'
// import { useTokenBalanceAfterConverted } from 'hooks/useTokenBalance'
import { CHAIN_INFO } from 'config/constants/networks'
import useSelectNetwork from 'hooks/useSelectNetwork'
import { GeneralText } from 'components/GlobalStyle'
// import { getATOAddress } from 'utils/addressHelpers'
import DAppNetworkSelector from './DAppNetworkSelector'

const StyledLogo = styled.img`
  @media only screen and (max-width: 1550px) {
    // display: none;
  }
`

export default function WalletModule() {
  const { chainId, library } = useActiveWeb3React()
  const { switchNetwork } = useSelectNetwork()
  // const intl = useIntl()
  // const [eth, setEther] = useState('0');
  const info = chainId ? CHAIN_INFO[chainId] : undefined
  // const { fastRefresh } = useRefresh();
  // const accountInfo = useActiveLoginReact();
  const onGetETHBalance = useGetETHBalance();
  // const onGetTokenBalanceAfterConverted = useTokenBalanceAfterConverted(getATOAddress());
  // useEffect(() => {
  //   async function getEtherBalance() {
  //     await library.getBalance(accountInfo.walletAddress).then((etherBalance) => {
  //       console.log(accountInfo);
  //       const balanceBeforeDivide = new BigNumber(etherBalance.toString());
  //       setEther(((balanceBeforeDivide.toNumber() / getCurrencyDetails("eth").totalDecimals)).toFixed(4).toString());
  //     });
  //   }
  //   try {
  //     if (accountInfo.walletAddress) {
  //       getEtherBalance();
  //     }

  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [account, accountInfo, library, fastRefresh]);

  const handleChainSwitch = useCallback(
    (targetChain: number) => {
      if (!library) return
      switchNetwork(targetChain)
    },
    [library, switchNetwork],
  )

  useEffect(() => {
    if (!chainId) return
    handleChainSwitch(chainId)
  }, [chainId, handleChainSwitch])

  if (!chainId || !info || !library) {
    return null
  }

  return (
    <Flex flexDirection="column" style={{ borderRight: "1px solid #01F3F6", borderLeft: "1px solid #01F3F6" }} >
      <DAppNetworkSelector />
      <Flex flexDirection="row" justifyContent="space-between" style={{ padding: "0px 8px 8px 8px" }}>
        <Flex flexDirection="row">
          <StyledLogo src='/images/logo-eth.png' className="logo" alt='logo' style={{ width: '30px', height: "30px" }} />
          <GeneralText color="white" fontSize="0.875rem" fontFamily="var(--fontFamily4)" weight={400} style={{ alignSelf: "center" }}>{onGetETHBalance} ETH</GeneralText>
        </Flex>
        {/* <Flex flexDirection="row" style={{ marginLeft: "2px" }}>
          <StyledLogo src='/images/logo-ato.png' className="logo" alt='logo' style={{ width: '30px', height: "30px" }} />
          <GeneralText color="white" fontSize="0.875rem" fontFamily="var(--fontFamily)" weight={200} style={{ alignSelf: "center" }}>{onGetTokenBalanceAfterConverted.fetchStatus === 'success' ? onGetTokenBalanceAfterConverted.balance : 0} ATO</GeneralText>
        </Flex> */}
      </Flex>
    </Flex>
  )
}
