
export const landingPaths = [
  'home',
  'service',
  'builder'
]

export const dappPaths = [
  'map',
  'map-builder',
  'map-group',
  'profile',
  'nft',
  'land',
  'explore',
  'reset-password',
  'verify-email',
  'login',
  'mapv2',
  'alpha-register',
  'scenes'

]

