import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const CurrentLifeCycle = `${process.env.REACT_APP_RELEASE_LIFECYCLE}`.toLowerCase()

const StyledI = styled.i`
  color: white;
`

function useComponentVisible(initialIsVisible) {
  const [showMenu, setShowMenu] = useState(
    initialIsVisible
  );
  const menuRef = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowMenu(false);
    }
  };

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { menuRef, showMenu, setShowMenu };
}

const SubMenu = () => {
  const {
    menuRef,
    showMenu,
    setShowMenu
  } = useComponentVisible(false);

  // const [showMenu, setShowMenu] = useState(false)
  const intl = useIntl()
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const handleShowMenu = () => {
    setShowMenu(false);
  }
  return (
    <>
      <div id='' className='navbar-area' style={{ paddingTop: "0px", paddingBottom: "0px", zIndex: 970, marginLeft: "0px" }}>
        <div className='raimo-responsive-nav'>
          <div className='container'>
            <div className='raimo-responsive-menu' ref={menuRef}>
              <div role='presentation' onClick={() => toggleMenu()} className='' style={{ textAlign: "right", fontSize: "2rem" }}>
                {showMenu ? (
                  <StyledI className='bx bx-x' />
                ) : (
                  <StyledI className='bx bx-menu' />
                )}
              </div>
              {/* <div className='logo'>
                <a href='/home'>
                  <StyledLogo src='/logo.png' alt='logo' style={{ maxWidth: '200px' }} />
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <nav className={showMenu ? 'show navbar navbar-expand-md navbar-light' : 'navbar navbar-expand-md navbar-light hide-menu'}>
          <div className='container'>
            <div className='collapse navbar-collapse mean-menu' style={{ justifyContent: "center" }}>
              <ul className='navbar-nav text-uppercase' style={{ top: "0px", marginLeft: "0px" }}>
                {/* <li className='nav-item'>
                    <Link to="/home"> {t("Home")} </Link>
                  </li> */}
                {/* <li className='nav-item'>
                    <Link to="/map" className={currLocation === "map" ? "active" : ""}> {t("Map")} </Link>
                  </li> */}

                <li className='nav-item px-2'>
                  <NavLink to="/profile" activeClassName='nav-active' style={{ color: "white", lineHeight: "40px" }} onClick={() => { handleShowMenu(); }}>{intl.formatMessage({ id: "profile", defaultMessage: "Profile" })}</NavLink>
                </li>
                <li className='nav-item px-2'>
                  <NavLink to="/explore" activeClassName='nav-active' style={{ color: "white", lineHeight: "40px" }} onClick={() => { handleShowMenu(); }}>{intl.formatMessage({ id: "explore", defaultMessage: "Explore" })}</NavLink>
                </li>
                <li className='nav-item px-2'>
                  <NavLink to="/scenes" activeClassName='nav-active' style={{ color: "white", lineHeight: "40px" }} onClick={() => { handleShowMenu(); }}>{intl.formatMessage({ id: "my-scenes", defaultMessage: "My Scenes" })}</NavLink>
                </li>
                <li className='nav-item px-2'>
                  <NavLink to="/nft" activeClassName='nav-active' style={{ color: "white", lineHeight: "40px" }} onClick={() => { handleShowMenu(); }}>{intl.formatMessage({ id: "nft", defaultMessage: "Nft" })}</NavLink>
                </li>
                <li className='nav-item px-2'>
                  <NavLink to="/land" activeClassName='nav-active' style={{ color: "white", lineHeight: "40px" }} onClick={() => { handleShowMenu(); }}> {intl.formatMessage({ id: "land", defaultMessage: "Land" })} </NavLink>
                </li>
                {/* <li className='nav-item'>
                  <a href='/' data-item='Staking' style={{ color: "white", lineHeight: "40px" }}>Staking</a>
                </li>
                <li className='nav-item'>
                  <a href='/' data-item='Referral' style={{ color: "white", lineHeight: "40px" }}>Referral</a>
                </li> */}
                {/* <li className='nav-item'>
                  <Link to="/inventory" className={currLocation === "inventory" ? "nav-active" : ""} style={{ color: "white", lineHeight: "40px" }}> {t("Inventory")} </Link>
                </li> */}
                {/* <li className='nav-item'>
                  <a href='/' data-item='Claim' style={{ color: "white", lineHeight: "40px" }}>Claim</a>
                </li>
                <li className='nav-item'>
                  <a href='/' data-item='Avatar' style={{ color: "white", lineHeight: "40px" }}>Avatar</a>
                </li>
                <li className='nav-item'>
                  <a href='/' data-item='Settings' style={{ color: "white", lineHeight: "40px" }}>Settings</a>
                </li>  */}
              </ul>
              <div className='others-option'>
                <div className='d-flex align-items-center'>
                  {/* <div className='option-item'>
                      <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                    </div> */}
                  {/* <div className='option-item'>
                      <NetworkSelector />
                    </div> */}
                  {/* <div className='option-item'>
                      {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default SubMenu
