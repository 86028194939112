import { ethers } from 'ethers'

// Addresses
import {
  getLandCashierAddress,
  getMulticallAddress
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import landCashierAbi from 'config/abi/landCashier.json'
import getProvider from './providers'

const getContract = (abi: any, address: string, chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const provider = getProvider(chainId)
  const signerOrProvider = signer ?? provider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, chainId, signer)
}

export const getErc721Contract = (address: string, chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, chainId, signer)
}

export const getMulticallContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), chainId, signer)
}

export const getLandCashierContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(landCashierAbi,getLandCashierAddress(), chainId, signer)
}
