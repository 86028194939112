import { useCallback } from 'react'
// import useToast from 'hooks/useToast'
import { useCookies } from 'react-cookie';
import useAuth from './useAuth'

/**
 * Use this hook to logout all wallet and cookie
 */

const useAuthWithEmail = () => {
  // const { toastError } = useToast()
  const { logout } = useAuth()
  const [cookie, setCookie, removeCookie] = useCookies();

  const logoutAll = useCallback(() => {
    if (localStorage) {
      localStorage.removeItem(`connectorIdv2`);
      localStorage.removeItem(`_user_details`);
      localStorage.removeItem(`_user_task_list`);
    }
    logout();
    removeCookie(
      '_account', { path: '/' }
    );
  }, [logout, removeCookie])

  return { logoutAll }
}

export default useAuthWithEmail
