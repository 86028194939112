import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useUpdateNetwork } from 'state/network/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import { landingPaths, dappPaths } from 'config/constants/paths' // Note: need update this file when add new page
import ScrollToTop from 'components/Shared/ScrollToTop'
import Menu from './components/Menu'
import DAppMenu from './components/DAppMenu'
import Footer from './components/Footer'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'

// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))
const Home = lazy(() => import('./views/Home'))
const Maas = lazy(() => import('./views/Maas'))
const Builder = lazy(() => import('./views/Builder'))
const ChangeLog = lazy(() => import('./views/Builder/ChangeLog'))
// const Map = lazy(() => import('./views/Map'))
const MapBuilder = lazy(() => import('./views/MapBuilder'))
const MapGroup = lazy(() => import('./views/MapGroup'))
const Profile = lazy(() => import('./views/Profile'))
const ResetPassword = lazy(() => import('./views/ResetPassword'))
const VerifyEmail = lazy(() => import('./views/VerifyEmail'))
const MapLeaflet = lazy(() => import('./views/MapLeaflet'))
const AlphaRegister = lazy(() => import('./views/AlphaRegister'))
const Scenes = lazy(() => import('./views/Profile/Scenes'))
const SceneDetails = lazy(() => import('./views/Profile/SceneDetails'))
const NFTs = lazy(() => import('./views/Profile/NFTs'))
const Markers = lazy(() => import('./views/Profile/Markers'))
const Avatars = lazy(() => import('./views/Profile/Avatars'))
const Explore = lazy(() => import('./views/Profile/Explore'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const MapBuilderEnable = parseInt(`${process.env.REACT_APP_MAP_BUILDER}`) === 1

const CurrentLifeCycle = `${process.env.REACT_APP_RELEASE_LIFECYCLE}`.toLowerCase()

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useUpdateNetwork()

  const pathName = window.location.pathname
  const currLocation = window.location.pathname.split("/")[1]

  return (

    <Router history={history}>
      <div className="master-container">
        <ScrollToTop />
        {dappPaths.includes(currLocation) ?
          <>
            <DAppMenu />
          </>
          : null
        }
        {landingPaths.includes(currLocation) ?
          <>
            <Menu />
          </>
          : null
        }
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Redirect from="/:url*(/+)" to={pathName.slice(0, -1)} />

            {dappPaths.includes(currLocation) ?
              <>
                <Route exact path="/home">
                  <Home />
                </Route>
                <Route exact strict path="/notfound" component={NotFound} />
                {
                  CurrentLifeCycle === "alpha" ?
                    <>
                      <Route exact strict path="/alpha-register" component={AlphaRegister} />
                      <Route exact strict path="/map-builder" >
                        <Redirect to="/notfound" />
                      </Route>
                      <Route exact strict path="/map-group" >
                        <Redirect to="/notfound" />
                      </Route>
                    </>
                    :
                    <>
                      <Route exact strict path="/alpha-register" >
                        <Redirect to="/notfound" />
                      </Route>
                      {MapBuilderEnable ? <Route exact strict path="/map-builder" component={MapBuilder} /> : ""}
                      <Route exact strict path="/map-group" component={MapGroup} />
                    </>
                }
                <Route exact strict path="/profile" component={Profile} />
                <Route exact strict path="/scenes" component={Scenes} />
                {/* <Route exact strict path="/land" component={Map} /> */}
                <Route exact strict path="/land" component={MapLeaflet} />
                <Route exact strict path="/explore" component={Explore} />
                <Route exact strict path="/nft" component={NFTs} />
                <Route exact strict path="/scenes/details/:uuid" component={SceneDetails} />
                <Route exact strict path="/scenes/details/:uuid/marker" component={Markers} />
                <Route exact strict path="/profile/avatar" component={Avatars} />
                <Route exact strict path="/reset-password" component={ResetPassword} />
                <Route exact strict path="/verify-email" component={VerifyEmail} />
                <Route exact strict path="/login" component={Profile} />

              </>
              : null
            }
            {landingPaths.includes(currLocation) ?
              <>
                <Route exact strict path="/home">
                  <Home />
                </Route>
                <Route exact path="/service">
                  <Maas />
                </Route>
                <Route exact path="/builder">
                  <Builder />
                </Route>
                <Route exact path="/builder/changelog">
                  <ChangeLog />
                </Route>
              </>
              : null
            }

            {/* Redirect */}
            <Redirect exact from="/" to="/home" />

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
        <ToastListener />
        <DatePickerPortal />
      </div>
      <Footer />
    </Router>
  )
}

export default React.memo(App)
