import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { isEmpty, isNil } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { FormattedMessage } from 'react-intl'
// import ConnectWalletButton from 'components/ConnectWalletButton'
// import AccountWalletButton from 'components/AccountWalletButton'
import { ButtonStyled, GeneralText } from 'components/GlobalStyle'
import { landingPaths, dappPaths } from 'config/constants/paths' // Note: need update this file when add new page
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import useRefresh from 'hooks/useRefresh'
import LangSelector from '../LangSelector'
import NetworkSelector from './NetworkSelector'

const StyledI = styled.i`
  color: white;
`

const StyledLogo = styled.img`
  @media only screen and (max-width: 1199px) {
    display: none;
  }
`
function useComponentVisible(initialIsVisible) {
  const [showMenu, setShowMenu] = useState(
    initialIsVisible
  );
  const menuRef = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowMenu(false);
    }
  };

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { menuRef, showMenu, setShowMenu };
}

const Menu = () => {
  const { account } = useWeb3React()

  const {
    menuRef,
    showMenu,
    setShowMenu
  } = useComponentVisible(false);


  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }


  const { t, setLanguage, currentLanguage } = useTranslation()

  const { slowRefresh } = useRefresh()
  const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/frontend/get-social-link`
  const [allLinks, setAllLinks] = useState<any>({})

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split("/")
  const currLocation = splitLocation[1]
  const hashLocation = location.hash

  // get all links
  useLayoutEffect(() => {
    const getAllLinks = async (): Promise<void> => {
      await axios.get(baseUrl).then((response) => {
        if (response && response.status === 200) {
          const respData = response.data.data
          setAllLinks(respData)
        }
      }).catch(error => {
        // console.log('error', error)
      })
    }

    getAllLinks()
  }, [baseUrl, slowRefresh])

  return (
    <>
      <div id='navbar' className='navbar-area'>
        <div className='raimo-responsive-nav'>
          <div className='container'>
            <div className='raimo-responsive-menu' ref={menuRef}>
              <div role='presentation' onClick={() => toggleMenu()} className='hamburger-menu'>
                {showMenu ? (
                  <StyledI className='bx bx-x' />
                ) : (
                  <StyledI className='bx bx-menu' />
                )}
              </div>
              <div className='logo'>
                <a href='/home'>
                  <StyledLogo src='/logo.png' alt='logo' style={{ maxWidth: '200px' }} />
                </a>
              </div>
              {dappPaths.includes(currLocation) ?
                <div className='responsive-others-option2'>
                  <div className='d-flex align-items-center'>
                    {/* <div className='option-item'>
                      <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                    </div> */}
                    <div className='option-item'>
                      <NetworkSelector />
                    </div>
                    {/* <div className='option-item'>
                      {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                    </div> */}
                  </div>
                </div>
                :
                <div className='responsive-others-option'>
                  <div className='d-flex align-items-center'>
                    <div className='option-item'>
                      <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                    </div>

                    {!isNil(allLinks.instagram) && allLinks.instagram !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.instagram} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/instagram.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.medium) && allLinks.medium !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.medium} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/medium.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.telegram) && allLinks.telegram !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.telegram} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/telegram.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.discord) && allLinks.discord !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.discord || null} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/discord.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.twitter) && allLinks.twitter !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.twitter} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/twitter.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <nav className={showMenu ? 'show navbar navbar-expand-md navbar-light' : 'navbar navbar-expand-md navbar-light hide-menu'}>
          <div className='container'>
            <a href='/home' className='navbar-brand'>
              <StyledLogo src='/logo.png' alt='logo' style={{ maxWidth: '200px' }} />
            </a>
            {landingPaths.includes(currLocation) ?
              <div className='collapse navbar-collapse mean-menu'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link to="/home" className={currLocation === "home" ? "active" : ""}> <FormattedMessage
                      id='home'
                      defaultMessage="Home"
                    /> </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to="/builder" className={currLocation === "builder" ? "active" : ""}>
                      <FormattedMessage
                        id='builder'
                        defaultMessage="Builder"
                      />
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to="/service" className={currLocation === "service" ? "active" : ""}> <FormattedMessage
                      id='service'
                      defaultMessage="Service"
                    /> </Link>
                  </li>
                  {!isNil(allLinks.gitbook) && allLinks.gitbook !== '' ?
                    <li className='nav-item'>
                      <a href={allLinks.gitbook} target="_BLANK" rel="noopener noreferrer">
                        <FormattedMessage
                          id='learn'
                          defaultMessage="Learn"
                        />
                      </a>
                    </li>
                    : null}
                  <li className='nav-item'>
                    <a href="/profile">
                      <ButtonStyled bgColor="linear-gradient(90deg, #10A7F2 4.29%, #4BF5CE 100%)" width="200px" height="45px">
                        <GeneralText color="var(--blackColor)" fontSize="1em" fontFamily="var(--fontFamily2)" weight={7}>
                          <FormattedMessage
                            id='start-building'
                            defaultMessage="Start Building"
                          />
                        </GeneralText>
                      </ButtonStyled>
                    </a>
                  </li>
                </ul>
                <div className='others-option'>
                  <div className='d-flex align-items-center'>
                    <div className='option-item'>
                      <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                    </div>

                    {!isNil(allLinks.instagram) && allLinks.instagram !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.instagram} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/instagram.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.medium) && allLinks.medium !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.medium} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/medium.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.telegram) && allLinks.telegram !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.telegram} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/telegram.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.discord) && allLinks.discord !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.discord || null} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/discord.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                    {!isNil(allLinks.twitter) && allLinks.twitter !== '' ?
                      <div className='option-item'>
                        <a className="text-white" href={allLinks.twitter} target="_BLANK" rel="noopener noreferrer">
                          <img src="/images/twitter.svg" alt="social" />
                        </a>
                      </div>
                      : null}
                  </div>
                </div>
              </div>
              : null}
            {dappPaths.includes(currLocation) ?
              <div className='collapse navbar-collapse mean-menu'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link to="/map" className={currLocation === "map" ? "active" : ""}> {t("Map")} </Link>
                  </li>
                </ul>
                <div className='others-option'>
                  <div className='d-flex align-items-center'>
                    {/* <div className='option-item'>
                      <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                    </div> */}
                    <div className='option-item'>
                      <NetworkSelector />
                    </div>
                    {/* <div className='option-item'>
                      {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                    </div> */}
                  </div>
                </div>
              </div>
              : null}
          </div>
        </nav>
      </div>
    </>
  )
}

export default Menu
