import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { isNil } from 'lodash'
import {
  Container,
} from 'react-bootstrap'
import {
  Flex,
} from '@pancakeswap/uikit'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { landingPaths } from 'config/constants/paths' // Note: need update this file when add new page
import { useTranslation } from 'contexts/Localization'
import useRefresh from 'hooks/useRefresh'
import {
  GeneralText,
} from 'components/GlobalStyle'

const FooterWrapper = styled.div`
  background: var(--blackColor);
  min-height: 150px;
  z-index: 2;
`

const FooterPanel = styled.div`
  width: 100%;
  padding: 30px;
`

const CopyrightLabel = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  font-family: var(--fontFamily);
  color: var(--neonBLightColor);
  letter-spacing: 0em;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
`

const MenuFlex = styled(Flex)`
  flex-direction: row;
  column-gap: 30px;
  font-size: 16px;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin-top: 20px;
  }
`

const SocialPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  column-gap: 20px;
`

function GoTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const { slowRefresh } = useRefresh()
  const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/frontend/get-social-link`
  const [allLinks, setAllLinks] = useState<any>({})
  const isMobile = useMediaQuery({ query: `(max-width: 992px)` })

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split("/")
  const currLocation = splitLocation[1]

  // get all links
  useLayoutEffect(() => {
    const getAllLinks = async (): Promise<void> => {
      await axios.get(baseUrl).then((response) => {
        if (response && response.status === 200) {
          const respData = response.data.data
          setAllLinks(respData)
        }
      })
    }

    if (landingPaths.includes(currLocation)) {
      getAllLinks()
    }
  }, [baseUrl, slowRefresh, currLocation])

  return (
    <>
      {landingPaths.includes(currLocation) ?
        <FooterWrapper>
          <Container fluid>
            <FooterPanel>
              <Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" flexWrap="wrap">
                <Flex flexDirection="column" alignItems="center">
                  <img width={234} src="/logo.png" alt="logo" />
                  <CopyrightLabel><i className='bx bx-copyright' /> {process.env.REACT_APP_PROJECT_NAME} {currentYear}</CopyrightLabel>
                </Flex>
                <MenuFlex>
                  <Link to="/home" className="footer-nav-text" onClick={GoTop}>
                    <FormattedMessage
                      id='home'
                      defaultMessage="Home"
                    />
                  </Link>
                  <Link to="/builder" className="footer-nav-text" onClick={GoTop}><FormattedMessage
                    id='builder'
                    defaultMessage="Builder"
                  /></Link>
                  <Link to="/service" className="footer-nav-text" onClick={GoTop}><FormattedMessage
                    id='service'
                    defaultMessage="Service"
                  /></Link>
                  {!isNil(allLinks.gitbook) && allLinks.gitbook !== '' ?
                    <a className="footer-nav-text" href={allLinks.gitbook} target="_BLANK" rel="noopener noreferrer">
                      <FormattedMessage
                        id='learn'
                        defaultMessage="Learn"
                      />
                    </a>
                    : null}
                </MenuFlex>
                <Flex flexDirection="column" alignItems="center">
                  <GeneralText color="var(--whiteColor)" fontSize="16px" fontFamily="var(--fontFamily)" weight={700} style={{ marginBottom: '20px' }}>
                    <FormattedMessage
                      id='follow-us'
                      defaultMessage="Follow Us"
                    />
                  </GeneralText>
                  <SocialPanel>
                    {!isNil(allLinks.instagram) && allLinks.instagram !== '' ?
                      <a className="text-white" href={allLinks.instagram} target="_BLANK" rel="noopener noreferrer">
                        <img src="/images/instagram.svg" alt="social" />
                      </a>
                      : null}
                    {!isNil(allLinks.medium) && allLinks.medium !== '' ?
                      <a className="text-white" href={allLinks.medium} target="_BLANK" rel="noopener noreferrer">
                        <img src="/images/medium.svg" alt="social" />
                      </a>
                      : null}
                    {!isNil(allLinks.telegram) && allLinks.telegram !== '' ?
                      <a className="text-white" href={allLinks.telegram} target="_BLANK" rel="noopener noreferrer">
                        <img src="/images/telegram.svg" alt="social" />
                      </a>
                      : null}
                    {!isNil(allLinks.twitter) && allLinks.twitter !== '' ?
                      <a className="text-white" href={allLinks.twitter} target="_BLANK" rel="noopener noreferrer">
                        <img src="/images/twitter.svg" alt="social" />
                      </a>
                      : null}
                    {!isNil(allLinks.discord) && allLinks.discord !== '' ?
                      <a className="text-white" href={allLinks.discord || null} target="_BLANK" rel="noopener noreferrer">
                        <img src="/images/discord.svg" alt="social" />
                      </a>
                      : null}
                  </SocialPanel>
                </Flex>
              </Flex>
            </FooterPanel>
          </Container>
        </FooterWrapper>
        : null}
    </>
  )
}

export default Footer
