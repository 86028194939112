import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useAuthWithEmail from './useAuthWithEmail';

const useMiddlewareTranslation = () => {
  const intl = useIntl();
  const { logoutAll } = useAuthWithEmail();
  const handleTranslation = useCallback(
    (translationText: string) => {
      switch (translationText) {
        // Login fail and check auth
        case "Old token, please login again":
          logoutAll();
          return intl.formatMessage({ id: "old-token-please-login-again", defaultMessage: "Old token, please login again" });
        case "jwt malformed":
          logoutAll();
          return intl.formatMessage({ id: "jwt-malformed", defaultMessage: "JWT Malformed" });
        // Map Status
        case "purchased":
          return intl.formatMessage({ id: "purchased", defaultMessage: "Purchased" });
        case "available":
          return intl.formatMessage({ id: "available", defaultMessage: "Available" });
        case "reserved":
          return intl.formatMessage({ id: "reserved", defaultMessage: "Reserved" });
        case "Arktory Map":
          return intl.formatMessage({ id: "arktory-map", defaultMessage: "Arktory Map" });
        case "The Arktory boasts 744,769 cabins lands that house the entire humankind":
          return intl.formatMessage({ id: "the-arktory-boasts-744769-cabins-lands-that", defaultMessage: "The Arktory boasts 744,769 cabins lands that house the entire humankind" });
        case "district":
          return intl.formatMessage({ id: "district", defaultMessage: "DISTRICT" });
        case "Cyberpunk":
          return intl.formatMessage({ id: "cyberpunk", defaultMessage: "Cyberpunk" });
        case "X-SPACE":
          return intl.formatMessage({ id: "x-space", defaultMessage: "X-SPACE" });
        // Wallet error message
        case "invalid payment token":
          return intl.formatMessage({ id: "invalid-payment-token", defaultMessage: "Invalid payment token" });
        case "Incorrect username or password.":
          return intl.formatMessage({ id: "incorrect-username-or-password", defaultMessage: "Incorrect username or password." });
        case "Email already registered":
          return intl.formatMessage({ id: "email-already-registered", defaultMessage: "Email already registered." });
        case "Bad signature":
          return intl.formatMessage({ id: "bad-signature", defaultMessage: "Bad signature" });
        // Scene error message
        case "Scene not found":
          return intl.formatMessage({ id: "scene-not-found", defaultMessage: "Scene not found" });
        case "Name is required":
          return intl.formatMessage({ id: "name-is-required", defaultMessage: "Name is required" })
        case "Name too short":
          return intl.formatMessage({ id: "name-too-short", defaultMessage: "Name too short" })
        case "Name too long":
          return intl.formatMessage({ id: "name-too-long", defaultMessage: "Name too long" })
        case "Description is required":
          return intl.formatMessage({ id: "description-is-required", defaultMessage: "Description is required" })
        case "Description too short":
          return intl.formatMessage({ id: "description-too-short", defaultMessage: "Description too short" })
        case "Description too long":
          return intl.formatMessage({ id: "description-too-long", defaultMessage: "Description too long" })

        //  API error message
        case "Account with this wallet address already exists":
          return intl.formatMessage({ id: "account-with-this-wallet-already-exists", defaultMessage: "Account with this wallet address already exists" });
        case "Email already registered or invalid email entered":
          return intl.formatMessage({ id: "email-already-registered-or", defaultMessage: "Email already registered or invalid email entered" });
        case "Token validation failed - E401":
          return intl.formatMessage({ id: "token-validation-failed-e401", defaultMessage: "Token validation failed - E401" });
        case "Too many password reset requests, please try again later":
          return intl.formatMessage({ id: "too-many-password-reset-requests-please", defaultMessage: "Too many password reset requests, please try again later" });
        case "Email not found":
          return intl.formatMessage({ id: "email-not-found", defaultMessage: "Email not found" });
        case "MetaMask Message Signature: User denied message signature.":
          return intl.formatMessage({ id: "user-denied-message-signature", defaultMessage: "User denied message signature." });
        case "MetaMask Tx Signature: User denied transaction signature.":
          return intl.formatMessage({ id: "user-denied-transaction-signature", defaultMessage: "User denied transaction signature." });
        case "Maximum avatar reached":
          return intl.formatMessage({ id: "maximum-avatar-reached", defaultMessage: "Maximum avatar reached" });
        case "Upload Avatar Image is Failed":
          return intl.formatMessage({ id: "upload-avatar-image-is-failed", defaultMessage: "Upload Avatar Image is Failed" });
        //  Task List
        case "Create your Avatar":
          return intl.formatMessage({ id: "create-your-avatar", defaultMessage: "Create your Avatar" });
        case "Create your first scene":
          return intl.formatMessage({ id: "create-your-first-scene", defaultMessage: "Create your first scene" });
        case "Publish your first scene":
          return intl.formatMessage({ id: "publish-your-first-scene", defaultMessage: "Publish your first scene" });
        case "Share your first metaverse to your social media":
          return intl.formatMessage({ id: "share-your-first-metaverse-to-your-social-media", defaultMessage: "Share your first metaverse to your social media" });
        // Create New Scene
        case "Gallery":
          return intl.formatMessage({ id: "gallery", defaultMessage: "Gallery" });
        case "Retails":
          return intl.formatMessage({ id: "retails", defaultMessage: "Retails" });
        case "Auditorium":
          return intl.formatMessage({ id: "auditorium", defaultMessage: "Auditorium" });
        case "Outdoor":
          return intl.formatMessage({ id: "outdoor", defaultMessage: "Outdoor" });
        default:
          return translationText;
      }
    }, [intl, logoutAll]
  )
  return { onGetTranslation: handleTranslation }

}

export default useMiddlewareTranslation;
