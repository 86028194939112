import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getStakingAddress = () => {
  return getAddress(addresses.stakingContr)
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLandCashierAddress = () => {
  return getAddress(addresses.landCashier)
}

export const getUSDTAddress = () => {
  return getAddress(addresses.usdt)
}

export const getATOAddress = () => {
  return getAddress(addresses.ato)
}

export const getPriceConvertorAddress = () => {
  return getAddress(addresses.priceConvertor)
}