import React from 'react'
import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'


const FixedPageCenterLoader: React.FC = () => {
    return (
        <>
            <div style={{ position: "fixed", zIndex: 998, width: "100%", height: "100%", alignItems: "center", textAlign: "center", top: "0px", left: "0px", backgroundColor: "black", opacity: '0.7' }} />
            <div style={{ position: "fixed", zIndex: 999, left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                <Flex flexDirection="column" alignItems="center">
                    <img id="" alt="img" src="/images/loading-arktory.gif" style={{ width: "120px", height: "120px" }} />
                </Flex>
            </div>
        </>
    )
}

export default FixedPageCenterLoader
