import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { ChevronDown } from 'react-feather'
import { Dropdown } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { CHAIN_ID, CHAIN_INFO } from 'config/constants/networks'
import useSelectNetwork from 'hooks/useSelectNetwork'

const StyledChevronDown = styled(ChevronDown)`
  width: 18px;
`

const SelectorControls = styled.div<{ interactive: boolean }>`
  align-items: center;
  background-color: transparent;
  color: #FFFF;
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 8px 10px;
`

const NetworkLabel = styled.div`
  flex: 1 1 auto;
  font-family: var(--fontFamily);
  margin-right: 6px;
`

const SelectorLabel = styled(NetworkLabel)`
  display: none;
  font-family: var(--fontFamily);
  font-weight:100!important;
  font-size:0.875rem;
  @media screen and (min-width: 500px) {
    display: block;
    margin-right: 8px;
  }
`

const FlyoutHeader = styled.div`
  color: var(--blackColor);
  font-family: var(--fontFamily);
  margin-bottom: 10px;
`

const FlyoutRow = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) => (active ? '#F7F8FA' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
  text-align: left;
  width: 100%;
  margin-top: 5px;
`

const FlyoutRowActiveIndicator = styled.div`
  background-color: #007D35;
  border-radius: 50%;
  height: 9px;
  width: 9px;
`

const ActiveRowWrapper = styled.div`
  background-color: #F7F8FA;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px;
  width: 100%;
`

function Row({
  targetChain,
  onSelectChain,
}: {
  targetChain: CHAIN_ID
  onSelectChain: (targetChain: number) => void
}) {
  const { library, chainId } = useActiveWeb3React()
  if (!library || !chainId) {
    return null
  }
  const active = chainId === targetChain
  const { label } = CHAIN_INFO[targetChain]

  const rowContent = (
    <FlyoutRow onClick={() => onSelectChain(targetChain)} active={active}>
      {/* <Logo src={logoUrl} /> */}
      <NetworkLabel>{label}</NetworkLabel>
      {chainId === targetChain && <FlyoutRowActiveIndicator />}
    </FlyoutRow>
  )

  if (active) {
    return (
      <ActiveRowWrapper>
        {rowContent}
      </ActiveRowWrapper>
    )
  }
  return rowContent
}

export default function DAppNetworkSelector() {
  const { chainId, library } = useActiveWeb3React()
  const { switchNetwork } = useSelectNetwork()
  const intl = useIntl()
  const info = chainId ? CHAIN_INFO[chainId] : undefined

  const handleChainSwitch = useCallback(
    (targetChain: number) => {
      if (!library) return
      switchNetwork(targetChain)
    },
    [library, switchNetwork],
  )

  useEffect(() => {
    if (!chainId) return
    handleChainSwitch(chainId)
  }, [chainId, handleChainSwitch])

  if (!chainId || !info || !library) {
    return null
  }

  return (
    <Dropdown
      target={
        <SelectorControls interactive>
          {/* <SelectorLogo interactive src={info.logoUrl} /> */}
          <SelectorLabel className="text-uppercase">{info.label} {intl.formatMessage({ id: "network", defaultMessage: "Network" })}</SelectorLabel>
          <StyledChevronDown />
        </SelectorControls>
      }
    >
      <FlyoutHeader>
        {intl.formatMessage({ id: "select-a-network", defaultMessage: "Select a network" })}
      </FlyoutHeader>
      <Row onSelectChain={handleChainSwitch} targetChain={CHAIN_ID.MAINNET} />
    </Dropdown>
  )
}
