import { useEffect } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useSwitchNetwork from 'hooks/useSelectNetwork'
import {
  State,
} from '../types'
import { fetchUserNetwork } from '.'


export const useNetworkChainId = (): number => {
  const chainId = useSelector((state: State) => state.network.data.chainId)
  return chainId
}

export const useNetworkChainIdFromUrl = (): boolean => {
  const chainIdFromUrl = useSelector((state: State) => state.network.data.chainIdFromUrl)
  return chainIdFromUrl
}

export const useUpdateNetwork = () => {
  const dispatch = useAppDispatch()
  const { chainId, account } = useActiveWeb3React()
  const appChainId = useNetworkChainId()
  const chainIdFromUrl = useNetworkChainIdFromUrl()
  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    if (chainIdFromUrl) {
      switchNetwork(appChainId)
    } else {
      dispatch(fetchUserNetwork(chainId, account, appChainId))
    }
    // Load initial vault state in update network to stop mount re-render
    // dispatch(setVaultsLoad(false))
  }, [chainId, account, appChainId, chainIdFromUrl, switchNetwork, dispatch])
}
